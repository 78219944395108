import { Row } from './Row';

export const Table = (props: { name: string, items: { [a: string]: number } }) => {
  const { name, items } = props;
  if (Object.keys(items).length === 0) {
    return <></>;
  }
  return (
    <>
      <table className='conf__table'>
        <tbody>
        <tr className='conf__table-caption'>
          <th>{name}</th>
          <th>артикул</th>
          <th>количество</th>
          <th>стоимость (за 1 шт.)</th>
          <th>итого</th>
        </tr>
        {Object.keys(items).map((art) => {
          return <Row key={art} art={art} count={items[art]} />;
        })}
        </tbody>
      </table>
    </>
  );
};