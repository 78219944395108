const data: Data = require('./data.json');
const prices: Prices = require('./prices.json');
const files: Files = require('./files.json');
type Data = {
  [K: string]: DataItem
}
type DataItem = {
  [K: string]: any,
  article?: string
  title: string
  sub_category: string
  category: string
  class2: string
  class1: string
  class4: string
  class3: string
  collection: string
  length: number
  dimmer: boolean
}
type Prices = {
  [K: string]: {
    'price': number,
    'price_sale': number,
    'sale': boolean
  }
}
type Files = {
  [K: string]: string
}

export function getTitleByArticle(article: string): string {
  try {
    return getDataByArticle(article)['title'] ?? '';
  } catch (e) {
    return '';
  }
}

export function getPriceByArticle(article: string): number {
  try {
    return prices[article].price;
  } catch (e) {
    return 0;
  }
}

export function getDataByArticle(article: string): DataItem {
  try {
    return data[article] ?? {};
  } catch (e) {
    return { category: '', dimmer: false, sub_category: '', collection: '', class1: '', class2: '', class3: '', class4: '', length: 0, title: '' };
  }
}

export function getData(): Data {
  return data;
}

export function getImageByArticle(article: string): string {
  try {
    article = article.replace('*', '');
    return files[article] ?? '';
  } catch (e) {
    return '';
  }
}

export function getLinkByArticle(article: string): string {
  return 'https://technolight.ru/rest/qr?art=' + article;
}