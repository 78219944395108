import { getImageByArticle, getLinkByArticle, getPriceByArticle } from '../../../data/data';

export const Row = (props:{art:string,count:number}) => {
  const {art,count} = props
  const img = getImageByArticle(art);
  const price = getPriceByArticle(art);
  return (
    <>
      <tr className='conf__table-row'>
        <td><img className='conf__table-pic' src={img} alt='' /></td>
        <td><a href={getLinkByArticle(art)} target={'_blank'} rel="noreferrer">{art}</a></td>
        <td>{count}</td>
        <td>{price} ₽</td>
        <td>{price * count} ₽</td>
      </tr>
    </>
  );
};