import { useContext, useEffect, useReducer } from 'react';
import { globalContext } from '../context/global';
import { Table } from '../ui/step4/Table';
import { getPriceByArticle } from '../../data/data';
import { stringify } from 'csv-stringify/browser/esm/sync';
import { useNavigate } from 'react-router-dom';

type Items = {
  [p: string]: { [art: string]: number },
  'аксессуары': { [art: string]: number },
  'треки': { [art: string]: number },
  'споты': { [art: string]: number },
  'питание': { [art: string]: number },
}

export const Step4 = () => {
  const { project } = useContext(globalContext);
  const navigate = useNavigate();
  const [force, forceUpdate] = useReducer(x => x + 1, 1);
  const { items, total } = project.toCart();

  function exportToExel() {
    const csvData = [];
    const columns = ['Номенклатура', 'Количество', 'Цена за штуку', 'цена'];
    csvData.push(columns);
    let total = 0;
    for (const itemsKey in items) {
      for (const article in items[itemsKey]) {
        const count = items[itemsKey][article];
        const price = getPriceByArticle(article);
        const prices = price * count;
        csvData.push([article, count, price, prices]);
        total += prices;
      }
    }
    csvData.push(['', '', 'Итого:', total]);

    const output = stringify(csvData, {
      bom: true,
      columns: columns,
      delimiter: ';'
    });
    writeFile('spec.csv', output);

  }

  function toCart() {
    const cartData:{[k:string]:number} = {};
    for (const itemsKey in items) {
      for (const article in items[itemsKey]) {
        cartData[article] = items[itemsKey][article];
      }
    }
    window.$.ajax({
      method: 'POST',
      url: '/addToCart',
      data: JSON.stringify(cartData),
      processData: false,
      contentType: 'application/json',
      dataType: 'json',
      success: (e) => {
        window.open("https://technolight.ru/cart")
      }
    });
  }

  function writeFile(name: string, value: string) {
    let val = value;
    const download = document.createElement('a');
    download.href = 'data:text/csv;content-disposition=attachment;filename=file,' + val;
    download.download = name;
    download.style.display = 'none';
    download.id = 'download';
    document.body.appendChild(download);
    document.getElementById('download')?.click();
    document.body.removeChild(download);
  }

  useEffect(() => {
    window.$(document).on('sideItemsUpdate', forceUpdate);
    window.$(document).on('sideUpdate', forceUpdate);
    return () => {
      window.$(document).off('sideItemsUpdate', forceUpdate);
      window.$(document).off('sideUpdate', forceUpdate);
    };
  });
  if (!project.getSidesPower()) {
    setTimeout(() => {
      navigate('/');
    }, 200);
    return <></>;
  }
  return (
    <>
      <main className='main'>
        <div className='conf__wrapper'>
          <section className='conf__basket'>
            {Object.keys(items).map((key) => {
              return <Table name={key} items={items[key]} />;
            })}
          </section>
          <section className='conf__result'>
            <p className='conf__result-data'>Итоговая сумма<span>{total} ₽</span></p>
            {/* <button className='conf__result-btn'>Отправить заказ</button> */}
            <button className='conf__result-btn' onClick={exportToExel}>Экспорт в exel</button>
            <button style={{marginTop:"15px"}} className='conf__result-btn' onClick={toCart}>В корзину</button>
          </section>
        </div>
      </main>
    </>
  );
};