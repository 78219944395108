import { BusBarType, ColorType, getTrackByConfig, MountType, Track } from '../../data/tracks';
import { Side } from './Side';
import { Summary as S, Summary } from './Summary';
import { Painter } from './Painter';
import { StageConfig } from 'konva/lib/Stage';
import { getSpotByArticle } from '../../data/spots';
import { getPowerByArticle, getPowerVariants, Power } from '../../data/powes';
import { getPriceByArticle } from '../../data/data';

export type Items = {
  [p: string]: { [art: string]: number },
  'аксессуары': { [art: string]: number },
  'треки': { [art: string]: number },
  'споты': { [art: string]: number },
  'питание': { [art: string]: number },
}

export interface ProjectType {
  A: Side;
  B: Side;
  C: Side;
  D: Side;
  Track?: Track;
}

export enum Variants {
  Unknown = 0,
  Line = '-',
  LineVertical = 'I',
  Angle1 = '◥',
  Angle2 = '◢',
  Angle3 = '◣',
  Angle4 = '◤',
  Triangle1 = 'U1',
  Triangle2 = 'U2',
  Triangle3 = 'U3',
  Triangle4 = 'U4',
  parallel = '=',
  parallelVertical = '| |',
  Rect = '■',
}

export class Project implements ProjectType {
  public name: string = '';
  public A: Side;
  public B: Side;
  public C: Side;
  public D: Side;
  public Track?: Track;
  public Sides: Side[] = [];
  public Type: Variants = Variants.Unknown;
  public Power?: Power;

  constructor(data: ProjectType) {
    this.A = data.A;
    this.B = data.B;
    this.C = data.C;
    this.D = data.D;
    this.Sides.push(data.A);
    this.Sides.push(data.B);
    this.Sides.push(data.C);
    this.Sides.push(data.D);
  }

  setName(name: string) {
    this.name = name;
  }

  setTrack(track?: Track) {
    if (track) {
      for (const sidesKey in this.Sides) {
        this.Sides[sidesKey].setTrack(track);
        if (this.Track) {
          if (this.Track.BusBar !== track.BusBar || this.Track.Color !== track.Color) {
            this.Sides[sidesKey].Spots = {};
            this.Sides[sidesKey].spotsLength = 0;
            this.Sides[sidesKey].power = 0;
          }
        }
      }
      this.unsetPower();
      this.getPower();
      this.Track = track;
      window.$(document).trigger('sideItemsUpdate');
    }
  }

  getSideCount() {
    let i = 0;
    for (const sidesKey in this.Sides) {
      if (this.Sides[sidesKey].isIsset()) i++;
    }
    return i;
  }

  getSidesPower() {
    let power = 0;
    for (const sidesKey in this.Sides) {
      power += this.Sides[sidesKey].power;
    }
    return power;
  }

  getType(): Variants {
    const count = this.getSideCount();
    switch (count) {
      case 0:

        return this.Type = Variants.Unknown;
      case 1:
        if ((this.A.isIsset() || this.C.isIsset())) return this.Type = Variants.Line;
        if ((this.D.isIsset() || this.B.isIsset())) return this.Type = Variants.LineVertical;
        return this.Type = Variants.Unknown;
      case 2:
        if ((this.A.isIsset() && this.C.isIsset())) return this.Type = Variants.parallel;
        if ((this.D.isIsset() && this.B.isIsset())) return this.Type = Variants.parallelVertical;

        if ((this.A.isIsset() && this.B.isIsset())) return this.Type = Variants.Angle1;
        if ((this.B.isIsset() && this.C.isIsset())) return this.Type = Variants.Angle2;
        if ((this.C.isIsset() && this.D.isIsset())) return this.Type = Variants.Angle3;
        if ((this.D.isIsset() && this.A.isIsset())) return this.Type = Variants.Angle4;
        return this.Type = Variants.Unknown;
      case 3:
        if (!this.A.isIsset()) return this.Type = Variants.Triangle3;
        if (!this.B.isIsset()) return this.Type = Variants.Triangle4;
        if (!this.C.isIsset()) return this.Type = Variants.Triangle1;
        if (!this.D.isIsset()) return this.Type = Variants.Triangle2;
        return this.Type = Variants.Unknown;
      case 4:
        return this.Type = Variants.Rect;
    }
    return this.Type;
  }

  getSummary(): Summary {
    return new Summary(this);
  }

  getModel(configKonva: StageConfig): Painter {
    return new Painter(this, configKonva);
  }

  getIssetSides(): Side[] {
    const result: Side[] = [];
    for (const side of this.Sides) {
      if (side.isIsset()) {
        result.push(side);
      }
    }
    return result;
  }

  getLength(): number {
    return this.A.length + this.B.length + this.C.length + this.D.length;
  }

  fromString(str: string, setters: {
    setColor: (value: ColorType) => void,
    setMount: (value: MountType) => void,
    setBusBar: (value: BusBarType) => void,
  }): Project {
    const _tmp = this.toArray();
    const Object: typeof _tmp = JSON.parse(str);
    this.A.setInc(Object.A?.Inc ?? 0);
    this.B.setInc(Object.B?.Inc ?? 0);
    this.C.setInc(Object.C?.Inc ?? 0);
    this.D.setInc(Object.D?.Inc ?? 0);
    if (Object.Track) {
      this.setTrack(getTrackByConfig({ ...Object.Track }));
      setters.setColor(Object.Track.Color);
      setters.setMount(Object.Track.Mount);
      setters.setBusBar(Object.Track.BusBar);
      if (this.Track) {
        try {
          this.A.Tracks = {};
          this.A.length = 0;
          Object.A.tracks.forEach((track) => {
            try {
              this.A._addTrack(this.Track, track.length);
            } catch (e) {
              console.warn(e);
            }
          });
        } catch (e) {
          console.warn(e);
        }
        try {
          this.B.Tracks = {};
          this.B.length = 0;
          Object.B.tracks.forEach((track) => {
            try {
              this.B._addTrack(this.Track, track.length);
            } catch (e) {
              console.warn(e);
            }
          });
        } catch (e) {
          console.warn(e);
        }
        try {
          this.C.Tracks = {};
          this.C.length = 0;
          Object.C.tracks.forEach((track) => {
            try {
              this.C._addTrack(this.Track, track.length);
            } catch (e) {
              console.warn(e);
            }
          });
        } catch (e) {
          console.warn(e);
        }
        try {
          this.D.Tracks = {};
          this.D.length = 0;
          Object.D.tracks.forEach((track) => {
            try {
              this.D._addTrack(this.Track, track.length);
            } catch (e) {
              console.warn(e);
            }
          });
        } catch (e) {
          console.warn(e);
        }
        window.$(document).trigger('sideUpdate');
        try {
          (Object.A.spots).forEach((spot) => {
            try {
              const s = getSpotByArticle(spot.article);
              if (s) {
                this.A.addSpot(s, spot.count);
              }
            } catch (e) {
              console.warn(e);
            }
          });
        } catch (e) {
          console.warn(e);
        }
        try {
          (Object.B.spots).forEach((spot) => {
            try {
              const s = getSpotByArticle(spot.article);
              if (s) {
                this.B.addSpot(s, spot.count);
              }
            } catch (e) {
              console.warn(e);
            }
          });
        } catch (e) {
          console.warn(e);
        }
        try {
          (Object.C.spots).forEach((spot) => {
            try {
              const s = getSpotByArticle(spot.article);
              if (s) {
                this.C.addSpot(s, spot.count);
              }
            } catch (e) {
              console.warn(e);
            }
          });
        } catch (e) {
          console.warn(e);
        }
        try {
          (Object.D.spots).forEach((spot) => {
            try {
              const s = getSpotByArticle(spot.article);
              if (s) {
                this.D.addSpot(s, spot.count);
              }
            } catch (e) {
              console.warn(e);
            }
          });
        } catch (e) {
          console.warn(e);
        }
      }
    }
    if (Object.Power) {
      this.setPower(getPowerByArticle(Object.Power.article));
    }
    return this;
  }

  toArray() {
    return {
      name: this.name,
      A: this.A.toArray(),
      B: this.B.toArray(),
      C: this.C.toArray(),
      D: this.D.toArray(),
      Track: this.Track?.toArray(),
      Power: this.getPower()?.toArray()
    };
  }

  toString() {
    return JSON.stringify(this.toArray(), null, ' ');
  }

  setPower(power?: Power) {
    if (power) {
      this.Power = power;
      window.$(document).trigger('sideItemsUpdate', this);
      window.$(document).trigger('projectPowerSet', this);
    }
  }

  unsetPower() {
    this.Power = undefined;
    window.$(document).trigger('sideItemsUpdate', this);
    window.$(document).trigger('projectPowerUnset', this);
  }

  getPower() {
    if (!this.Track) return undefined;
    if (this.Power) {
      const powers = getPowerVariants(this.Track, this.getSidesPower());
      let canBe = false;
      powers.forEach((power) => {
        if (power.article === this.Power?.article) {
          canBe = true;
        }
      });
      if (!canBe) {
        this.unsetPower();
        return undefined;
      }
    }
    return this.Power;
  }

  toCart() {
    const summary: S = this.getSummary();
    const { sides, accessories, spots, power, profiles } = summary.summary();
    let total = 0;
    const items: Items
      = {
      'треки': {},
      'аксессуары': {},
      'споты': {},
      'питание': {}
    };
    accessories.forEach(a => {
      if (items['аксессуары'][a.article] === undefined) {
        items['аксессуары'][a.article] = a.count;
      } else {
        items['аксессуары'][a.article] += a.count;
      }
    });
    profiles.forEach(a => {
      if (items['аксессуары'][a.article] === undefined) {
        items['аксессуары'][a.article] = a.count;
      } else {
        items['аксессуары'][a.article] += a.count;
      }
    });
    sides.forEach(s => {
      s.data.forEach(d => {
        if (items['треки'][d.article] === undefined) {
          items['треки'][d.article] = d.count;
        } else {
          items['треки'][d.article] += d.count;
        }
      });
      s.fasteners.forEach(f => {
        if (items['аксессуары'][f.article] === undefined) {
          items['аксессуары'][f.article] = f.count;
        } else {
          items['аксессуары'][f.article] += f.count;
        }
      });
    });
    Object.values(spots).forEach(sp => {
      if (items['споты'][sp.spot.article] === undefined) {
        items['споты'][sp.spot.article] = sp.count;
      } else {
        items['споты'][sp.spot.article] += sp.count;
      }
    });
    Object.values(items).forEach((sp) => {
      for (const art in sp) {
        total += getPriceByArticle(art) * sp[art];
      }
    });
    if (power && this.Track) {
      items['питание'][power.article] = 1;
      const a = power.getAccessories(this.Track);
      a.forEach((a) => {
        items['питание'][a.article] = 1;
      });
    }
    return { items, total: total };
  }
}