import { useContext } from 'react';
import { globalContext } from '../../context/global';
import { Summary as S } from '../../classes/Summary';
import { SummaryItem } from './SummaryItem';
import { SummaryAccessoriesItem } from './SummaryAccessoriesItem';

export const Summary = () => {
  const { project } = useContext(globalContext);
  const summary: S = project.getSummary();

  const { sides, accessories,profiles } = summary.summary();
  return (
    <>
      <section className='conf__summary'>
        <h2 className='conf__summary-title'>Резюме</h2>
        <table className='conf__summary-table'>
          <thead>
          <tr>
            <th className='conf__summary-column conf__summary-column_name' colSpan={2}>пункт</th>
            <th className='conf__summary-column conf__summary-column_length'>длина</th>
            <th className='conf__summary-column conf__summary-column_value'>количество</th>
          </tr>
          </thead>
          <tbody>
          {sides.map((s) => {
            return <SummaryItem key={s.name} s={s} />;
          })}
          {accessories.length ?
            <tr>
              <td className='conf__summary-column conf__summary-column_caption' colSpan={99}>Угловые соединители</td>
            </tr> : <></>
          }
          {accessories.map((s) => {
            return <SummaryAccessoriesItem key={s.article} s={s} />;
          })}
          {profiles.length ?
            <tr>
              <td className='conf__summary-column conf__summary-column_caption' colSpan={99}>Профили</td>
            </tr> : <></>
          }
          {profiles.map((s) => {
            return <SummaryAccessoriesItem key={s.article} s={s} />;
          })}
          </tbody>
        </table>
      </section>
    </>
  );
};