import { NavLink, useLocation } from 'react-router-dom';
import * as React from 'react';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { globalContext } from '../context/global';
import { BusBarType } from '../../data/tracks';
import usePopper from '../hooks/usePopper';

export const Header = (props: { isDesktop: boolean }) => {
  const { isDesktop } = props;
  const menuActive = useCallback((props: { isActive: boolean; isPending: boolean; }) => {
    return (props.isActive ? 'header__nav-item active' : 'header__nav-item');
  }, []);
  const { Track, project, isAuth } = useContext(globalContext);
  const [projectName, setProjectName] = useState(window?.PROJECT_NAME ?? '');
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  const [step4, setStep4] = useState(false);

  const NavLinkStep1 = useCallback((props: { isActive: boolean; isPending: boolean; }) => {
    return menuActive(props);
  }, [menuActive]);
  const NavLinkStep2 = useCallback((props: { isActive: boolean; isPending: boolean; }) => {
    const cls = menuActive(props);
    return cls + (step2 ? '' : ' disabled');

  }, [menuActive, step2]);
  const NavLinkStep3 = useCallback((props: { isActive: boolean; isPending: boolean; }) => {
    const cls = menuActive(props);
    return cls + (step3 ? '' : ' disabled');

  }, [step3, menuActive]);
  const NavLinkStep4 = useCallback((props: { isActive: boolean; isPending: boolean; }) => {
    const cls = menuActive(props);
    return cls + (step4 ? '' : ' disabled');
  }, [step4, menuActive]);


  useEffect(() => {
    setStep2(false);
    if (Track) {
      setStep2(true);
    }
  }, [Track]);
  const step1Button = useRef(null);
  const [errorText1, setErrorText1] = useState('');
  const [sv, setSv] = useState('Сохранить');
  const [errorText2, setErrorText2] = useState('Не выбран тип трека');
  const [errorText3, setErrorText3] = useState('Не добавлен ни 1 трек');
  const [errorText4, setErrorText4] = useState('НЕ выбран блок питания, не добавлен ни 1 светильник');

  const Popper2 = usePopper({ element: '#step2Button', body: errorText2 });
  const Popper3 = usePopper({ element: '#step3Button', body: errorText3 });
  const Popper4 = usePopper({ element: '#step4Button', body: errorText4 });
  const location = useLocation();

  function PopperCheck() {
    if (step2) {
      setErrorText2('');
    } else if (location.pathname === '/') {
      setErrorText2('Не выбран тип трека');
    }
    if (step3) {
      setErrorText3('');
    } else if (location.pathname === '/step2') {
      setErrorText3('Не добавлен ни 1 трек');
    }
    if (step4) {
      setErrorText4('');
    } else if (location.pathname === '/step3') {
      let msg = [];
      if (!project.getPower()) {
        msg.push('не выбран блок питания');
      }
      if (!project.getSidesPower()) {
        msg.push('не добавлен ни 1 светильник');
      }
      setErrorText4(msg.join(', '));
    }
  }

  useEffect(PopperCheck, [location, project, step2, step3, step4]);

  function close2(e: { target: any; }) {
    if ($(e.target).parents('.header').length === 0) {
      setModalSaveConf(false);
      setModalResetConf(false);
    }
  }

  useEffect(() => {
    $(document).on('click', close2);
    return () => {
      $(document).off('click', close2);
    };
  });

  function sideUpdate() {
    PopperCheck();
    if (project.getSideCount() > 0) {
      setStep3(true);
    } else {
      setStep3(false);
    }
  }

  function sideItemsUpdate() {
    PopperCheck();
    if (project.getSidesPower() > 0 && (project.Track?.BusBar === BusBarType.fullSize || project.getPower())) {
      setStep4(true);
    } else {
      setStep4(false);
    }
  }

  function reset() {
    window.$(document).trigger('reset');
    setModalResetConf(false);
  }

  function save() {
    if (isAuth) {
      project.setName(projectName);
      window.$(document).trigger('save', { project });
      console.debug(project.toArray());
    }
    setSv('Сохраненно');
    setTimeout(() => {
      setSv('Сохранить');
    }, 1000);
  }

  const [modalResetConf, _setModalResetConf] = useState(false);
  const [modalSaveConf, _setModalSaveConf] = useState(false);

  function setModalResetConf(value: boolean) {
    _setModalResetConf(value);
    _setModalSaveConf(false);
  }

  function setModalSaveConf(value: boolean) {
    _setModalSaveConf(value);
    _setModalResetConf(false);
  }

  useEffect(() => {
    window.$(document).on('sideUpdate', sideUpdate);
    window.$(document).on('sideItemsUpdate', sideItemsUpdate);
    return () => {
      window.$(document).off('sideUpdate', sideUpdate);
      window.$(document).on('sideItemsUpdate', sideItemsUpdate);
    };
  });
  return (
    <>
      <header className='header'>
        {/*block variables */}
        <div className='conf__wrapper'>
          <h1 className='header__logo'>конфигуратор</h1>
          <nav className='header__nav'>
            <ul className='header__nav-list'>
              <li id={'step1Button'}>
                <NavLink ref={step1Button} className={NavLinkStep1} to='/' end>шаг 1</NavLink>
              </li>
              <li
                id={'step2Button'} onMouseEnter={() => {
                Popper2.showPopper();
              }} onMouseLeave={() => {
                Popper2.hidePopper();
              }}
              >
                <NavLink className={NavLinkStep2} to='/step2' end>шаг 2</NavLink>
              </li>
              <li
                id={'step3Button'} onMouseEnter={() => {
                Popper3.showPopper();
              }} onMouseLeave={() => {
                Popper3.hidePopper();
              }}
              >
                <NavLink className={NavLinkStep3} to='/step3' end>шаг 3</NavLink>
              </li>
              <li
                id={'step4Button'} onMouseEnter={() => {
                Popper4.showPopper();
              }} onMouseLeave={() => {
                Popper4.hidePopper();
              }}
              >
                <NavLink className={NavLinkStep4} to='/step4' end>шаг 4</NavLink>
              </li>
            </ul>

          </nav>
          {isDesktop ?
            <div className='header__buttons'>
              <button id='btnResetConf' onClick={() => setModalResetConf(true)} data-toggle='modal' data-direction='show' data-target-id='modalResetConf'>Сбросить</button>
              <button id='btnSaveConf' onClick={() => setModalSaveConf(true)} data-toggle='modal' data-direction='show' data-target-id='modalSaveConf'>Сохранить</button>
            </div>
            : <></>}
          <div className={`modal modal_header ${modalResetConf ? 'show' : ''}`} id='modalResetConf'>
            <div className='modal__dialog'>
              <div className='modal__content'>
                <button className='modal__close' onClick={() => setModalResetConf(false)} data-toggle='modal' data-direction='hide' data-target-id='modalResetConf'></button>
                <div className='modal__header'>
                  <h4 className='modal__title'>Вы уверены?</h4>
                </div>
                <div className='modal__footer'>
                  <button className='modal__btn' onClick={() => reset()} data-toggle='modal' data-direction='hide' data-target-id='modalResetConf'>Сбросить</button>
                </div>
              </div>
            </div>
          </div>
          <div className={`modal modal_header ${modalSaveConf ? 'show' : ''}`} id='modalSaveConf'>
            <div className='modal__dialog'>
              <div className='modal__content'>
                <button className='modal__close' onClick={() => setModalSaveConf(false)} data-toggle='modal' data-direction='hide' data-target-id='modalSaveConf'></button>
                <div className='modal__header'>
                  {!isAuth ?
                    <h4 className='modal__title'>Для сохранения конфигурации,<br />нужно авторизироваться на сайте <a target='_blank' href='https://technolight.ru/'>technolight.ru</a></h4>
                    : <>
                      <form className='modal__form'>
                        <h2 className='modal__form-title'>Имя проекта</h2>
                        <input className='modal__form-controller' type='text' value={projectName} onChange={(e) => setProjectName(e.target.value)} placeholder='Новый проект' />
                      </form>
                    </>}
                </div>
                <div className='modal__footer'>
                  {!isAuth ?
                    <button className='modal__btn' onClick={() => setModalSaveConf(false)} data-toggle='modal' data-direction='hide' data-target-id='modalSaveConf'>Закрыть</button>
                    :
                    <button className='modal__btn' onClick={() => save()} data-toggle='modal' data-direction='hide' data-target-id='modalSaveConf'>{sv}</button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {Popper2.html}
      {Popper3.html}
      {Popper4.html}
    </>
  );
};