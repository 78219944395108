import { Side, Sides } from '../../classes/Side';
import React, { useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { Spot } from '../../../data/spots';
import { step3Context } from '../../context/step3';
import { globalContext } from '../../context/global';
import { SpotItem } from './SpotItem';


export const RightPanelSide = (props: { side: Sides, active: Sides, isSelected: boolean, onSelect: (side: Sides) => void }) => {
  const { side, active, isSelected, onSelect } = props;
  const { project } = useContext(globalContext);
  const { activeSide } = useContext(step3Context);
  const Side: Side = useMemo(() => {
    return project[side];
  }, [project, side]);
  const [percent, setPercent] = useState<number>(Side.getFillPercent());
  const [force, forceUpdate] = useReducer(x => x + 1, 1);

  const [show, setShow] = useState(active === side);

  function sideItemsUpdate() {
    forceUpdate();
    setPercent(Side.getFillPercent());
  }

  function sideUpdate() {
    setPercent(Side.getFillPercent());
  }

  useEffect(() => {
    window.$(document).on('sideItemsUpdate', sideItemsUpdate);
    window.$(document).on('sideUpdate', sideUpdate);
    return () => {
      window.$(document).off('sideItemsUpdate', sideItemsUpdate);
      window.$(document).off('sideUpdate', sideUpdate);
    };
  });


  const removeSpot = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>, spot: Spot) => {
    if (activeSide) {
      switch (activeSide) {
        case Sides.A:
          project.A.removeSpot(spot);
          break;
        case Sides.B:
          project.B.removeSpot(spot);
          break;
        case Sides.C:
          project.C.removeSpot(spot);
          break;
        case Sides.D:
          project.D.removeSpot(spot);
          break;
      }
    }
  }, [activeSide, project]);

  return (
    <>
      <div className={`conf__data-side ${show ? 'show' : `${isSelected ? '' : 'hidden'}`}`} id={`side-${side}`}>
        <div className='conf__data-header' onClick={() => onSelect(side)}>
          <p className='conf__data-description'>сторона<span>{side}</span></p>
          <p className='conf__data-length'>{Side.spotsLength}/{Side.length} <small style={{ textTransform: 'lowercase' }}>мм</small></p>
          <p className='conf__data-percents'>
            <span className='conf__data-loader' style={{ width: `${percent}%` }}></span>
            <span className='conf__data-text'>заполнение</span>
            <span className='conf__data-value'>{percent}%</span>
            <span className='conf__data-text'>{Side.spotsLength}/{Side.length} <small style={{ textTransform: 'lowercase' }}>мм</small></span>
          </p>
          <button className='conf__data-toggle' onClick={()=>{setShow(!show)}}>
            <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                fillRule='evenodd' clipRule='evenodd'
                d='M19.6944 7.80432C20.1019 8.20988 20.1019 8.86741 19.6944 9.27295L12.7378 16.1959C12.3303 16.6014 11.6697 16.6014 11.2622 16.1958L4.30564 9.2728C3.89813 8.86725 3.89812 8.20972 4.30562 7.80417C4.71312 7.39861 5.37381 7.39861 5.78132 7.80416L12 13.9929L18.2187 7.80429C18.6262 7.39875 19.2869 7.39876 19.6944 7.80432Z'
                fill='black'
              ></path>
            </svg>
          </button>
        </div>
        <div className='conf__data-body custom-scroll-horizontal'>
          <ul className='conf__data-list'>
            {Object.values(Side.Spots).map((props: { spot: Spot, count: number }, index) => {
              const { spot, count } = props;
              return <SpotItem count={count} key={spot.article} cls={'conf__data-item'} spot={spot} onClick={(e, spot) => removeSpot(e, spot)} />;
            })}
          </ul>
        </div>
      </div>
    </>
  );
};