import * as React from 'react';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { randomString } from '../../../Helper';


export const Radio = (props: { help?: string | ReactNode, name: string, label?: string | ReactNode, value: any, onChange: (value: any) => void, currentValue?: any, canBeSelected?: boolean }) => {
  let { label, name, help, value, onChange, currentValue, canBeSelected } = props;
  if (canBeSelected === undefined) {
    canBeSelected = true;
  }
  const [helpOpen, setHelpOpen] = useState(false);
  const [uniClass, setUniClass] = useState('');
  useEffect(() => {
    setUniClass(randomString());
  }, []);
  const closeClick = useCallback((event: any) => {
    if (!$(event.target).hasClass(uniClass)) {
      setHelpOpen(false);
    }
  }, [uniClass]);
  useEffect(() => {
    window.$(document).on('click', closeClick);
    return () => {
      window.$(document).off('click', closeClick);
    };
  }, [closeClick]);

  function unCheck(event:  React.MouseEvent<HTMLInputElement, MouseEvent>) {
    if(currentValue === value) {
      window.$(event.target).prop('checked', false);
      onChange(null)
    }
  }

  return (
    <div className='conf__selects-controller'>
      <label>
        <input
          className='invisible' type='radio' name={name} value='' checked={currentValue === value} onClick={unCheck} onChange={() => {
          onChange(value);
        }} data-disabled={!canBeSelected}
        />
        <span className='checker'></span>
        <span className='label'>{label ?? value}</span>
      </label>
      {help ? (
        <div className={helpOpen ? 'helper visible ' + uniClass : 'helper ' + uniClass} onClick={() => setHelpOpen(!helpOpen)}>
          <div className='content'>
            <>{help}</>
            <span className='close' onClick={() => setHelpOpen(false)}></span>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};