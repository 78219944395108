import { Project, Variants } from './Project';
import Konva from 'konva';
import { StageConfig } from 'konva/lib/Stage';
import { Group } from 'konva/lib/Group';
import { Shape, ShapeConfig } from 'konva/lib/Shape';
import powerSvg from './../images/ic_power.svg';
import EventEmitter from 'events';
import { Track } from '../../data/tracks';
import { Side, Sides } from './Side';
import Stage = Konva.Stage;
import Layer = Konva.Layer;

interface MyShapeConfig extends ShapeConfig {
  mouseover?: (shape: _Shape) => void;
  mouseout?: (shape: _Shape) => void;
  mousedown?: (shape: _Shape) => void;
  defaultFill?: string;
}

export declare interface Painter {
  on(event: 'click', listener: (elem: _Shape, track: Track, side: Side) => void): this;

  on(event: 'dragstart', listener: (elem: _Shape) => void): this;

  on(event: 'dragend', listener: (elem: _Shape) => void): this;

  on(event: 'wheel', listener: (elem: _Shape) => void): this;

  on(event: string, listener: Function): this;
}

export declare interface _Shape extends Shape<MyShapeConfig> {
  getAttr<T>(attr: string): T;
}

export class Painter extends EventEmitter {
  readonly node_width: number = 30;
  readonly node_height: number = 30;
  readonly connector_width: number = 10;
  readonly connector_length: number = 30;
  readonly strokeWidth: number = 0;
  public stage: Stage;
  readonly scaleBy = 1.10;
  readonly connectorColor: string = '#A7A3A1';
  readonly trackColor: string = '#7F7F7F';
  public layer: Layer;
  public layer2: Layer;
  private activeSide?: Sides;

  constructor(public project: Project, public configKonva: StageConfig) {
    super();
    const self = this;
    this.stage = new Konva.Stage(this.configKonva);
    this.layer = new Konva.Layer();
    this.layer2 = new Konva.Layer();
    this.stage.add(this.layer);
    this.stage.add(this.layer2);
    this.stage.on('wheel', function(e) {
      // stop default scrolling
      e.evt.preventDefault();

      const oldScale = self.stage.scaleX();
      const pointer = self.stage.getPointerPosition();
      if (!pointer) {
        return;
      }
      const mousePointTo = {
        x: (pointer.x - self.stage.x()) / oldScale,
        y: (pointer.y - self.stage.y()) / oldScale
      };

      // how to scale? Zoom in? Or zoom out?
      let direction = e.evt.deltaY < 0 ? 1 : -1;
      if (direction === 1) {
        if (oldScale > 2) return;
      } else {
        if (oldScale < 0.2) return;
      }
      // when we zoom on trackpad, e.evt.ctrlKey is true
      // in that case lets revert direction
      if (e.evt.ctrlKey) {
        direction = -direction;
      }

      const newScale = direction > 0 ? oldScale * self.scaleBy : oldScale / self.scaleBy;

      self.stage.scale({ x: newScale, y: newScale });

      const newPos = {
        x: pointer.x - mousePointTo.x * newScale,
        y: pointer.y - mousePointTo.y * newScale
      };
      self.stage.position(newPos);
      self.emit('wheel', this);
    });
    this.stage.on('dragstart', function() {
      self.emit('dragstart', this);
    });
    this.stage.on('dragend', function() {
      self.emit('dragend', this);
    });
    const events = [
      'mouseover',
      'mouseout',
      'mousedown',
      'mouseup',
      'wheel',
      'dblclick',
      'pointercancel',
      'dragstart',
      'dragmove',
      'dragend'
    ];
    for (const eventsKey in events) {
      const e = events[eventsKey];
      this.stage.on(e, function(evt) {
        try {
          const shape = evt.target as Shape<MyShapeConfig>;

          if (Object.prototype.hasOwnProperty.call(shape?.attrs, e)) {
            shape.attrs[e].call(shape, shape, evt);
          }
        } catch (e) {
          console.warn('mouseover', e);
        }

      });
    }
  }

  public async render(activeSide: Sides) {
    this.activeSide = activeSide;
    const type = this.project.getType();
    switch (type) {
      case Variants.Line:
      case Variants.LineVertical:
      case Variants.parallel:
      case Variants.parallelVertical:
        await this.render_Line();
        break;
      case Variants.Angle1:
        await this.render_Angle1();
        break;
      case Variants.Angle2:
        await this.render_Angle2();
        break;
      case Variants.Angle3:
        await this.render_Angle3();
        break;
      case Variants.Angle4:
        await this.render_Angle4();
        break;
      case Variants.Triangle1:
        await this.render_Triangle1();
        break;
      case Variants.Triangle2:
        await this.render_Triangle2();
        break;
      case Variants.Triangle3:
        await this.render_Triangle3();
        break;
      case Variants.Triangle4:
        await this.render_Triangle4();
        break;
      case Variants.Rect:
        await this.renderRect();
        break;
    }
  }

  private async render_Line() {
    const self = this;

    let X = 150;
    let Y = 150;
    const Sides = this.project.getIssetSides();
    await this.renderPower({ X, Y });
    if (this.project.getType() === Variants.parallelVertical || this.project.getType() === Variants.LineVertical) {
      Y += this.node_width + 0.2;
    } else {
      X += this.node_width + 0.2;
    }

    for (const side of Sides) {
      const r = this.renderSide({ X, Y, side });
      X = r.X;
      Y = r.Y;
      if (side.isVertical()) {
        X += 200;
      } else {
        Y += 200;
      }
    }

  }

  private async render_Angle1() {
    const self = this;
    let X = 150;
    let Y = 150;
    await this.renderPower({ X, Y });
    X += this.node_width + 0.2;
    const r = this.renderSide({ X, Y, side: this.project.A });
    X = r.X;
    Y = r.Y;
    const connector = new Konva.Shape<MyShapeConfig>(
      {
        name: 'connector',
        stroke: 'red',
        fill: '#C1BBB7',
        defaultFill: '#C1BBB7',
        x: X,
        y: Y,
        strokeWidth: this.strokeWidth,
        sceneFunc: (context, shape) => {
          context.beginPath();
          context.rect(0, 0, this.node_width, this.node_width);
          context.closePath();
          context.fillStrokeShape(shape);
        }
      }
    );
    this.layer.add(connector);
    Y += this.node_width + 0.2;

    return this.renderSide({ X, Y, side: this.project.B });

  }

  private async render_Angle2() {
    const self = this;
    let X = 550;
    let Y = 150;
    await this.renderPower({ X, Y });
    Y += this.node_width + 0.2;
    const r = this.renderSide({ X, Y, side: this.project.B });
    X = r.X;
    Y = r.Y;
    const connector = new Konva.Shape<MyShapeConfig>(
      {
        name: 'connector',
        stroke: 'red',
        fill: '#C1BBB7',
        defaultFill: '#C1BBB7',
        x: X,
        y: Y,
        strokeWidth: this.strokeWidth,
        sceneFunc: (context, shape) => {
          context.beginPath();
          context.rect(0, 0, this.node_width, this.node_width);
          context.closePath();
          context.fillStrokeShape(shape);
        }
      }
    );
    this.layer.add(connector);

    return this.renderSide({ X, Y, side: this.project.C });
  }

  async renderPower(props: { X: number, Y: number }) {
    const { X, Y } = props;
    const power = new Konva.Shape<MyShapeConfig>(
      {
        name: 'power',
        stroke: 'red',
        fill: '#C1BBB7',
        defaultFill: '#C1BBB7',
        x: X,
        y: Y,
        strokeWidth: this.strokeWidth,
        sceneFunc: (context, shape) => {
          context.beginPath();
          context.rect(0, 0, this.node_width, this.node_width);
          context.closePath();
          context.fillStrokeShape(shape);
        }
      }
    );
    this.layer.add(power);
    return new Promise((resolve) => {
      Konva.Image.fromURL(powerSvg, (imageNode: Shape<ShapeConfig> | Group) => {
        this.layer2.add(imageNode);
        imageNode.setAttrs({
          X: X,
          Y: Y,
          width: this.node_width,
          height: this.node_height
        });
        resolve(true);
      });
    });
  }

  renderSide(props: { X: number, Y: number, side: Side }) {
    const self = this;
    let { X, Y, side } = props;
    const tracks = Object.values(side.Tracks);
    let fill = this.trackColor;
    if (this.activeSide && side.name === this.activeSide) {
      fill = 'red';
    }
    for (const k in tracks) {
      const track = tracks[k];
      const length = track.length / 10;
      let tr: MyShapeConfig = {
        track: track,
        name: track.id,
        length: length,
        stroke: 'black',
        strokeWidth: this.strokeWidth,
        x: X,
        y: Y,
        fill: fill,
        defaultFill: fill,
        mouseover: function(shape) {
          shape.fill('red');
          self.emit('mouseover', shape, track, side);
        },
        mouseout: function(shape) {
          shape.fill(shape.attrs.defaultFill);
          self.emit('mouseout', shape, track, side);
        },
        mousedown: function(shape) {
          self.emit('click', shape, track, side);
        }
      };
      if (side.isVertical()) {
        tr.sceneFunc = (context, shape) => {
          context.beginPath();
          const length = shape.getAttr('length');
          if (side.name === Sides.D) {
            context.rect(0, 0, this.node_width, -length);
          } else {
            context.rect(0, 0, this.node_width, length);
          }
          context.closePath();
          context.fillStrokeShape(shape);
        };
        if (side.name === Sides.D) {
          Y -= length + 0.2;
        } else {
          Y += length + 0.2;
        }
        if (k < (tracks.length - 1).toString()) {
          const connector = new Konva.Shape<MyShapeConfig>({
            name: track.id + '_connector',
            length: this.connector_length,
            stroke: 'black',
            strokeWidth: this.strokeWidth,
            opacity: 0.6,
            x: X,
            y: Y,
            fill: this.connectorColor,
            sceneFunc: (context, shape) => {
              context.beginPath();
              const length = shape.getAttr('length');
              context.rect(this.center(this.node_width, this.connector_width), -length / 2, this.connector_width, length);
              context.closePath();
              context.fillStrokeShape(shape);
            }
          });
          this.layer2.add(connector);
        }
      } else {
        tr.sceneFunc = (context, shape) => {
          context.beginPath();
          const length = shape.getAttr('length');
          if (side.name === Sides.C) {
            context.rect(0, 0, -length, this.node_width);
          } else {
            context.rect(0, 0, length, this.node_width);
          }
          context.closePath();
          context.fillStrokeShape(shape);
        };
        if (side.name === Sides.C) {
          X -= length + 0.2;
        } else {
          X += length + 0.2;
        }
        if (k < (tracks.length - 1).toString()) {
          const connector = new Konva.Shape<MyShapeConfig>({
            name: track.id + '_connector',
            length: this.connector_length,
            stroke: 'black',
            strokeWidth: this.strokeWidth,
            opacity: 0.6,
            x: X,
            y: Y,
            fill: this.connectorColor,
            sceneFunc: (context, shape) => {
              context.beginPath();
              const length = shape.getAttr('length');
              context.rect(-length / 2, this.center(this.node_width, this.connector_width), length, this.connector_width);
              context.closePath();
              context.fillStrokeShape(shape);
            }
          });
          this.layer2.add(connector);

        }
      }
      this.layer.add(new Konva.Shape<MyShapeConfig>(tr));
    }
    return { X, Y };
  }


  private async render_Angle3() {
    const self = this;
    let X = 500;
    let Y = 500;
    await this.renderPower({ X, Y });
    const r = this.renderSide({ X, Y, side: this.project.C });
    X = r.X;
    Y = r.Y;
    X -= this.node_width + 0.2;
    const connector = new Konva.Shape<MyShapeConfig>(
      {
        name: 'connector',
        stroke: 'red',
        fill: '#C1BBB7',
        defaultFill: '#C1BBB7',
        x: X,
        y: Y,
        strokeWidth: this.strokeWidth,
        sceneFunc: (context, shape) => {
          context.beginPath();
          context.rect(0, 0, this.node_width, this.node_width);
          context.closePath();
          context.fillStrokeShape(shape);
        }
      }
    );
    this.layer.add(connector);
    return this.renderSide({ X, Y, side: this.project.D });
  }

  private async render_Angle4() {
    const self = this;
    let X = 150;
    let Y = 500;
    await this.renderPower({ X, Y });

    const r = this.renderSide({ X, Y, side: this.project.D });
    X = r.X;
    Y = r.Y;
    Y -= this.node_width + 0.2;
    const connector = new Konva.Shape<MyShapeConfig>(
      {
        name: 'connector',
        stroke: 'red',
        fill: '#C1BBB7',
        defaultFill: '#C1BBB7',
        x: X,
        y: Y,
        strokeWidth: this.strokeWidth,
        sceneFunc: (context, shape) => {
          context.beginPath();
          context.rect(0, 0, this.node_width, this.node_width);
          context.closePath();
          context.fillStrokeShape(shape);
        }
      }
    );
    this.layer.add(connector);
    X += this.node_width + 0.2;
    return this.renderSide({ X, Y, side: this.project.A });
  }

  center(w1: number, w2: number) {
    return (w1 / 2 - w2 / 2);
  }

  private async render_Triangle1() {
    let { X, Y } = await this.render_Angle4();
    const connector = new Konva.Shape<MyShapeConfig>(
      {
        name: 'connector',
        stroke: 'red',
        fill: '#C1BBB7',
        defaultFill: '#C1BBB7',
        x: X,
        y: Y,
        strokeWidth: this.strokeWidth,
        sceneFunc: (context, shape) => {
          context.beginPath();
          context.rect(0, 0, this.node_width, this.node_width);
          context.closePath();
          context.fillStrokeShape(shape);
        }
      }
    );
    this.layer.add(connector);
    Y += this.node_width + 0.2;
    return this.renderSide({ X, Y, side: this.project.B });
  }

  private async render_Triangle2() {
    let { X, Y } = await this.render_Angle1();
    const connector = new Konva.Shape<MyShapeConfig>(
      {
        name: 'connector',
        stroke: 'red',
        fill: '#C1BBB7',
        defaultFill: '#C1BBB7',
        x: X,
        y: Y,
        strokeWidth: this.strokeWidth,
        sceneFunc: (context, shape) => {
          context.beginPath();
          context.rect(0, 0, this.node_width, this.node_width);
          context.closePath();
          context.fillStrokeShape(shape);
        }
      }
    );
    this.layer.add(connector);
    return this.renderSide({ X, Y, side: this.project.C });
  }

  private async render_Triangle3() {
    let { X, Y } = await this.render_Angle2();
    const connector = new Konva.Shape<MyShapeConfig>(
      {
        name: 'connector',
        stroke: 'red',
        fill: '#C1BBB7',
        defaultFill: '#C1BBB7',
        x: X,
        y: Y,
        strokeWidth: this.strokeWidth,
        sceneFunc: (context, shape) => {
          context.beginPath();
          context.rect(0, 0, this.node_width, this.node_width);
          context.closePath();
          context.fillStrokeShape(shape);
        }
      }
    );
    this.layer.add(connector);
    return this.renderSide({ X, Y, side: this.project.D });
  }

  private async render_Triangle4() {
    let { X, Y } = await this.render_Angle3();
    const connector = new Konva.Shape<MyShapeConfig>(
      {
        name: 'connector',
        stroke: 'red',
        fill: '#C1BBB7',
        defaultFill: '#C1BBB7',
        x: X,
        y: Y,
        strokeWidth: this.strokeWidth,
        sceneFunc: (context, shape) => {
          context.beginPath();
          context.rect(0, 0, this.node_width, this.node_width);
          context.closePath();
          context.fillStrokeShape(shape);
        }
      }
    );
    this.layer.add(connector);
    X += this.node_width + 0.2;
    return this.renderSide({ X, Y, side: this.project.A });
  }

  private async renderRect() {
    let { X, Y } = await this.render_Triangle1();
    const connector = new Konva.Shape<MyShapeConfig>(
      {
        name: 'connector',
        stroke: 'red',
        fill: '#C1BBB7',
        defaultFill: '#C1BBB7',
        x: X,
        y: Y,
        strokeWidth: this.strokeWidth,
        sceneFunc: (context, shape) => {
          context.beginPath();
          context.rect(0, 0, this.node_width, this.node_width);
          context.closePath();
          context.fillStrokeShape(shape);
        }
      }
    );
    this.layer.add(connector);
    return this.renderSide({ X, Y, side: this.project.C });
  }
}
