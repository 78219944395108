import { Articles, Track } from '../../data/tracks';
import { Spot } from '../../data/spots';
import { getImageByArticle, getTitleByArticle } from '../../data/data';

export enum Sides {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
}

export interface TrackLineType {
  id: string;
  side: Sides;
  track: Track;
  length: number;

}

export class TrackLine implements TrackLineType {
  public length: number;
  public side: Sides;
  public track: Track;
  public id: string;

  constructor(data: TrackLineType) {
    this.length = data.length;
    this.side = data.side;
    this.track = data.track;
    this.id = data.id;
  }

  setTrack(track: Track) {
    this.track = track;
  }

  public getArticle(): string {
    const a: keyof Articles = 2000;
    return this.track.Articles[a].article;
  }

  public getImage(): string {
    const a: keyof Articles = 2000;
    return this.track.Articles[a].image || getImageByArticle(this.track.Articles[a].article);
  }

  public getTitle(): string {
    const a: keyof Articles = 2000;
    return this.track.Articles[a].title || getTitleByArticle(this.track.Articles[a].article) || this.track.Articles[a].article;
  }

  public toArray() {
    return {
      side: this.side,
      length: this.length,
    };
  }
}

export interface SideType {
  name: Sides;
  Tracks: { [K: string]: TrackLine };
  length: number;
}

export class Side implements SideType {
  Tracks: { [K: string]: TrackLine };
  length: number;
  Spots: { [K: string]: { spot: Spot, count: number } } = {};
  name: Sides;
  public spotsLength: number = 0;
  private Inc: number = 0;
  private isset: boolean = true;
  public power: number = 0;

  constructor(data: SideType) {
    this.length = data.length;
    this.Tracks = data.Tracks;
    this.name = data.name;
    this.Inc = 0;
  }

  setTrack(track: Track) {
    for (const k in this.Tracks) {
      this.Tracks[k].setTrack(track);
    }
  }

  setIsset(isset: boolean): void {
    this.isset = isset;
  }

  isIsset(): boolean {
    return this.isset && this.length > 0;
  }

  newId(): string {
    this.Inc++;
    return this.name + this.Inc.toString();
  }

  public _addTrack(track?: Track, length?: number) {
    if (!track || !length) {
      throw new Error('Track must be specified');
    }
    const id: string = this.newId();
    this.Tracks[id] = new TrackLine({ track: track, side: this.name, id, length });
    this.length += length;
  }

  addTrack(track?: Track, length?: number) {
    if (!track || !length) {
      throw new Error('Track must be specified');
    }
    this.length += length;
    this.fixLine(track);
    window.$(document).trigger('sideUpdate', this);
  }

  _addCustom(track?: Track, length?: number) {
    if (!track || !length) {
      throw new Error('Track must be specified');
    }
    if (length > 2000) {
      const ost = length % 2000;
      let $i = Math.floor(length / 2000);
      while ($i-- > 0) {
        this._addTrack(track, 2000);
      }
      if (ost) {
        this._addTrack(track, ost);
      }
    } else {
      this._addTrack(track, length);
    }
  }

  fixLine(track: Track) {
    let length = this.length;
    if (track && length) {
      this.Tracks = {};
      this.length = 0;
      this._addCustom(track, length);
    }else{
      this.Tracks = {};
      this.length = 0;
    }
  }

  private _removeTrack(id: string) {
    this.length -= this.Tracks[id].length;
    delete this.Tracks[id];
    if (this.length < 0) {
      this.length = 0;
    }
  }

  removeTrack(id: string) {
    this._removeTrack(id);
    window.$(document).trigger('sideUpdate', this);
  }

  removeTrackByLength(track?: Track, length?: number) {
    if (!track || !length) {
      throw new Error('Track must be specified');
    }
    this.length -= length;
    if (this.length < 0) {
      this.length = 0;
    }
    this.fixLine(track);
    window.$(document).trigger('sideUpdate', this);
  }

  isVertical(): boolean {
    return this.name === Sides.B || this.name === Sides.D;
  }

  addSpot(spot: Spot, count: number = 1): boolean {
    while (count--) {
      if (this.getFillPercent() > 80) {
        return false;
      }
      if (typeof this.Spots[spot.article] !== 'undefined') {
        this.Spots[spot.article].count++;
      } else {
        this.Spots[spot.article] = { spot: spot, count: 1 };
      }
      this.spotsLength += spot.connector_length;
      this.power += spot.power;
      window.$(document).trigger('sideItemsUpdate', this);
    }
    return true;
  }

  removeSpot(spot: Spot) {
    if (typeof this.Spots[spot.article] !== 'undefined') {
      this.Spots[spot.article].count--;
    }
    if (this.Spots[spot.article].count <= 0) {
      delete this.Spots[spot.article];
    }
    this.spotsLength -= spot.connector_length;
    this.power -= spot.power;
    if (this.spotsLength < 0) {
      this.spotsLength = 0;
    }
    if (this.power < 0) {
      this.power = 0;
    }
    window.$(document).trigger('sideItemsUpdate', this);
    return true;
  }

  getSpots(){
    return this.Spots
  }

  getFillPercent(): number {
    const l = (this.spotsLength / this.length) * 100;
    if (isNaN(l)) {
      return 0;
    }
    return Math.round(l);
  }

  setInc(value: number) {
    this.Inc = value;
  }

  toArray() {
    const tracks = [];
    const spots = [];
    for (const tracksKey in this.Tracks) {
      tracks.push(this.Tracks[tracksKey].toArray());
    }
    for (const spotsKey in this.Spots) {
      spots.push({ article: this.Spots[spotsKey].spot.article, count: this.Spots[spotsKey].count });
    }
    return {
      tracks: tracks,
      spots: spots,
      Inc: this.Inc
    };
  }
}