import { Model } from '../ui/step3/Model';
import { useContext, useEffect, useReducer } from 'react';
import { globalContext } from '../context/global';
import { Spots } from '../ui/step3/Spots';
import { RightPanel } from '../ui/step3/RightPanel';
import { useNavigate } from 'react-router-dom';

export const Step3 = () => {
  const { Track, project } = useContext(globalContext);
  const navigate = useNavigate()
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  function sideUpdate() {
    forceUpdate();
  }

  useEffect(() => {
    window.$(document).on('sideUpdate', sideUpdate);
    return () => {
      window.$(document).off('sideUpdate', sideUpdate);
    };
  });

  if (!(Track && project.getIssetSides().length)) {
    setTimeout(() => {
      navigate('/')
    },200)
    return <></>;
  }
  return (
    <>
      <main className='main'>
        <div className='conf__wrapper'>
          <Model />
          <RightPanel />
          <Spots />
        </div>
      </main>
    </>
  );
};