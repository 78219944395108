import { step2Context } from '../../context/step2';
import * as React from 'react';
import { useCallback, useContext, useState } from 'react';
import { globalContext } from '../../context/global';
import { Side } from '../../classes/Side';
import { trim } from '../../../Helper';

export const RectTrackLine = (props: { side: Side }) => {
  const { side } = props;
  const { Track } = useContext(globalContext);
  const { activeSide } = useContext(step2Context);
  const entries = Object.entries(side.Tracks);
  const [modalUserSize, setModalUserSize] = useState(false);
  const [UserSize, _setUserSize] = useState(500);

  function setUserSize(value: string) {
    let v = parseInt(value);
    if (!isNaN(v)) {
      _setUserSize(v);
    }else{
      _setUserSize(0);
    }
  }

  const setUserSizeFinal = useCallback(() => {
    let v = UserSize;
    if (isNaN(v)) {
      return;
    }
    if (v < 200) {
      v = 200;
    }
    _setUserSize(v);
    side.addTrack(Track, v);
  }, [Track, UserSize, side]);
  return (
    <>
      <div className={`conf__elements-block conf__elements-block-${side.name} ${side.name === activeSide ? 'active' : ''}`}>
        <div className='conf__elements-increments'>
          <button className='conf__elements-inc' onClick={() => {
            side.addTrack(Track, 2000);
          }} >
            <span>2000 мм</span>
            <mark></mark>
          </button>
          <button className='conf__elements-inc' onClick={() => {
            side.removeTrackByLength(Track, 2000);
          }} >
            <span>2000 мм</span>
            <mark className={'dec'}></mark>
          </button>
          <button className='conf__elements-inc' onClick={() => setModalUserSize(true)}>
            <span>пользовательский</span>
            <mark></mark>
          </button>
        </div>
        <div className='conf__elements-viewport custom-scroll-horizontal'>
          <div className='conf__elements-content'><span className='conf__elements-prev'></span>
            <div className='conf__elements-list'>
              {entries.map(([key, Track], i) => (
                <button
                  style={{ width: (Track.length / 10) + 'px' }}
                  key={key} onClick={() => {
                  side.removeTrack(Track.id);
                }}
                >{Track.length}</button>
              ))}
            </div>
            <button
              className='conf__elements-add' onClick={() => {
              side.addTrack(Track, 2000);
            }}
            >Добавить трек
            </button>
            <span className='conf__elements-next'></span>
          </div>
        </div>
      </div>

      <div className={modalUserSize ? 'modal show' : 'modal'} id='modalUserSize' onMouseUp={() => setModalUserSize(false)}>
        <div className='modal__dialog' onMouseUp={(e) => e.stopPropagation()}>
          <div className='modal__content'>
            <button className='modal__close' data-toggle='modal' data-direction='hide' data-target-id='modalUserSize' onMouseUp={() => setModalUserSize(false)}></button>
            <div className='modal__header'>
              <h4 className='modal__title'>Добавить заказной размер</h4>
            </div>
            <div className='modal__body'>
              <form className='modal__form' onSubmit={(e) => {setUserSizeFinal();setModalUserSize(false);e.preventDefault()}}>
                <h2 className='modal__form-title'>Введите произвольный размер в мм*</h2>
                <input
                  className='modal__form-controller' onChange={event => setUserSize(event.target.value)} value={trim(UserSize.toString(),'0')} type='number' min='200'
                />
              </form>
            </div>
            <div className='modal__footer'>
              <button className='modal__form-btn' data-toggle='modal' data-direction='hide' data-target-id='modalUserSize' onClick={() => setModalUserSize(false)}>Закрыть</button>
              <button className='modal__form-btn' onClick={() => {setUserSizeFinal();setModalUserSize(false)}}>Добавить трек</button>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};