import { Sides } from '../../classes/Side';


export const RectSide = (props: { side: Sides, active: Sides, isSelected: boolean, onSelect: (side: Sides) => void }) => {
  const { side, active, isSelected, onSelect } = props;
  return (
    <>
      <div className={`conf__sides-el conf__sides-el-${side} ` + (active === side ? 'active' : '') + ' ' + (isSelected ? 'selected' : '')} onClick={() => onSelect(side)}>
        {/* <button className='conf__sides-btn' onClick={() => onClick(side)}></button> */}
        <span className='conf__sides-letter'>{side}</span>
      </div>
    </>
  );
};