import { SummaryAccessoriesItemType } from '../../classes/Summary';
import { getImageByArticle } from '../../../data/data';

export const SummaryAccessoriesItem = (props: {

  s: SummaryAccessoriesItemType
}) => {

  const { s } = props;
  if(!s.image){
    s.image = getImageByArticle(s.article)
  }
  return (
    <>
      <tr>
        <td className='conf__summary-column conf__summary-column_image' style={{ textAlign: 'left', width: 50 }}><img
          style={{ maxHeight: 50, maxWidth: 50 }} alt={s.article} title={s.article} src={s.image || 'https://via.placeholder.com/50'}
        /></td>
        <td className='conf__summary-column conf__summary-column_name' colSpan={2}>{s.title}</td>
        <td className='conf__summary-column conf__summary-column_value'>{s.count}</td>
      </tr>
    </>
  );
};