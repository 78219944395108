import { useContext, useEffect, useReducer } from 'react';
import { globalContext } from '../../context/global';
import { getPowerVariants } from '../../../data/powes';


export const RightPanelPower = () => {
  const { project } = useContext(globalContext);
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  const powers = (() => {
    if (project.Track) return getPowerVariants(project.Track, project.getSidesPower());
    return [];
  })();

  useEffect(() => {
    window.$(document).on('sideItemsUpdate', forceUpdate);
    return () => {
      window.$(document).off('sideItemsUpdate', forceUpdate);
    };
  });
  const power = project.getPower();
  let header = <p className='conf__data-percents'>
    <span className='conf__data-loader' style={{ width: '100%' }}></span>
    <span className='conf__data-text'>Требуемая мощность</span>
    <span className='conf__data-value'>{project.getSidesPower()} W</span>
  </p>;
  if (power) {
    header = <p className='conf__data-percents'>
      <span className='conf__data-loader' style={{ width: '100%' }}></span>
      <span className='conf__data-text'>Выбран: {power.article}</span>
      <span className='conf__data-value'>{project.getSidesPower()}/{power.Power} W</span>
    </p>;
  }
  return (
    <>
      <div className={`conf__data-side conf__data-side_custom show`}>
        <div className='conf__data-header'>
          <p className='conf__data-description'>Блок питания</p>
          {header}
        </div>
        <div className='conf__data-body custom-scroll-horizontal'>
          <ul className='conf__data-list'>
            {powers.map((power) => {
              return <li key={power.article} className='conf__data-item'>
                <button className={`conf__data-btn ${power.article === project.getPower()?.article ? 'active' : ''}`} onClick={() => project.setPower(power)}>
                  <span className='conf__data-pic' style={{ backgroundImage: 'url(\'' + power.image + '\')' }}></span>
                  <span className='conf__data-label'>{power.name}</span>
                  <span className='conf__data-size'>мощность {power.Power} W</span>
                  <span className='conf__data-price'>цена {power.price} ₽</span>
                </button>
              </li>;

            })}
          </ul>
        </div>
      </div>
    </>
  );
};