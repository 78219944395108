import { useContext, useEffect, useReducer } from 'react';
import { globalContext } from '../context/global';
import { Rect } from '../ui/step2/Rect';
import { Summary } from '../ui/step2/Summary';
import { useNavigate } from 'react-router-dom';

export const Step2 = () => {
  const { Track } = useContext(globalContext);
  const navigate = useNavigate()
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  function sideUpdate() {
    forceUpdate();
  }

  useEffect(() => {
    window.$(document).on('sideUpdate', sideUpdate);
    return () => {
      window.$(document).off('sideUpdate', sideUpdate);
    };
  });
  if (!Track) {
    setTimeout(() => {
      navigate('/')
    },200)
    return <></>;
  }
  return (
    <>
      <main className='main'>
        <div className='conf__wrapper'>
          <Rect></Rect>
          {/* <Model /> */}
          <Summary></Summary>
        </div>
      </main>
    </>
  );
};