import { useContext } from 'react';
import { step2Context } from '../../context/step2';
import { Sides } from '../../classes/Side';

export const RectButtons = () => {
  const { A, B, C, D, activeSide, setActiveSide } = useContext(step2Context);

  return (
    <>
      <button className={`conf__elements-btn ${A.isIsset() ? 'selected' : ''} ${activeSide === Sides.A ? 'active' : ''}`} onClick={() => setActiveSide(Sides.A)}>
        <span className='conf__elements-letter'>{Sides.A}</span>
        <span className='conf__elements-value'>{A.length} мм</span>
      </button>

      <button className={`conf__elements-btn ${B.isIsset() ? 'selected' : ''} ${activeSide === Sides.B ? 'active' : ''}`} onClick={() => setActiveSide(Sides.B)}>
        <span className='conf__elements-letter'>{Sides.B}</span>
        <span className='conf__elements-value'>{B.length} мм</span>
      </button>

      <button className={`conf__elements-btn ${C.isIsset() ? 'selected' : ''} ${activeSide === Sides.C ? 'active' : ''}`} onClick={() => setActiveSide(Sides.C)}>
        <span className='conf__elements-letter'>{Sides.C}</span>
        <span className='conf__elements-value'>{C.length} мм</span>
      </button>

      <button className={`conf__elements-btn ${D.isIsset() ? 'selected' : ''} ${activeSide === Sides.D ? 'active' : ''}`} onClick={() => setActiveSide(Sides.D)}>
        <span className='conf__elements-letter'>{Sides.D}</span>
        <span className='conf__elements-value'>{D.length} мм</span>
      </button>
    </>
  );
};