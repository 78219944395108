export function randomString(length = 6) {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghiklmnopqrstuvwxyz'.split('');
  if (!length) {
    length = Math.floor(Math.random() * chars.length);
  }
  let str = '';
  for (let i = 0; i < length; i++) {
    str += chars[Math.floor(Math.random() * chars.length)];
  }
  return str;
}

export function trim(s: string, l?: string,r?: string) {
  if (!l) {
    l = ' ';
  }
  if (!r) {
    r = ' ';
  }
  if (l === ']') l = '\\]';
  if (l === '^') l = '\\^';
  if (l === '\\') l = '\\\\';

  if (r === ']') r = '\\]';
  if (r === '^') r = '\\^';
  if (r === '\\') r = '\\\\';
  return s.replace(new RegExp(
    '^[' + l + ']+|[' + r + ']+$', 'g'
  ), '');
}