import { createContext } from 'react';
import { Side, Sides } from '../classes/Side';

export interface step2 {
  A: Side;
  B: Side;
  C: Side;
  D: Side;
  activeSide: Sides;
  setActiveSide: (value: Sides) => void;
}

export const step2Context = createContext<step2>({
  activeSide: Sides.A, setActiveSide(value: Sides): void {
  },
  A: new Side({ length: 0, name: Sides.A, Tracks: {} }),
  B: new Side({ length: 0, name: Sides.B, Tracks: {} }),
  C: new Side({ length: 0, name: Sides.C, Tracks: {} }),
  D: new Side({ length: 0, name: Sides.D, Tracks: {} })
});