import * as React from 'react';
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Layout } from './components/ui/Layout';
import { NotFound } from './components/page/NotFound';
import { Step1 } from './components/page/Step1';
import './components/css/style.css';
import { globalContext } from './components/context/global';
import { BusBarType, ColorType, MountType, Track } from './data/tracks';
import { step1Context } from './components/context/step1';
import { step2Context } from './components/context/step2';
import { step3Context } from './components/context/step3';
import { Step2 } from './components/page/Step2';
import { Side, Sides } from './components/classes/Side';
import { Project } from './components/classes/Project';
import { Step3 } from './components/page/Step3';
import { getSpotsFromData } from './data/spots';
import { Step4 } from './components/page/Step4';

export default function App() {
  const innerWidthLimit = 1200;
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= innerWidthLimit);
  const [isAuth, setIsAuth] = useState(false);

  function userAuth(e: any, value: boolean) {
    debugger
    console.log(e);
    setIsAuth(value);
    console.log('is auth ' + value);
  }

  useEffect(() => {
    window.$(document).on('userAuth', userAuth);
    return () => {
      window.$(document).off('userAuth', userAuth);
    };
  });

  const [DATA, setDATA] = useState<string>(window.DATA ?? '');
  const [Track, _setTrack] = useState<Track>();
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  //Проверка Экрана
  const onResize = useCallback(() => {
    setIsDesktop(window.innerWidth >= innerWidthLimit);
  }, []);
  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);


  //установка title страницы
  const location = useLocation();
  useEffect(() => {
    switch (location.pathname) {
      case '/':
        window.document.title = 'Конфигуратор Technolight - Этап 1';
        break;
      case '/step2':
        window.document.title = 'Конфигуратор Technolight - Этап 2';
        break;
      case '/step3':
        window.document.title = 'Конфигуратор Technolight - Этап 3';
        break;
      case '/step4':
        window.document.title = 'Конфигуратор Technolight - Этап 4';
        break;
    }
  }, [location]);
  //Инициализация
  const [Color, setColor] = useState<ColorType>();
  const [Mount, setMount] = useState<MountType>();
  const [BusBar, setBusBar] = useState<BusBarType>();
  const [A, setA] = useState<Side>(new Side({ length: 0, name: Sides.A, Tracks: {} }));
  const [B, setB] = useState<Side>(new Side({ length: 0, name: Sides.B, Tracks: {} }));
  const [C, setC] = useState<Side>(new Side({ length: 0, name: Sides.C, Tracks: {} }));
  const [D, setD] = useState<Side>(new Side({ length: 0, name: Sides.D, Tracks: {} }));
  const [project, newProject] = useState<Project>(new Project({ A, B, C, D, Track }));
  const [activeSide, setActiveSide] = useState<Sides>(Sides.A);
  const [scaleX, setScaleX] = useState(0);
  const [scaleY, setScaleY] = useState(0);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const Spots = useMemo(() => {
    if (Track) {
      return getSpotsFromData(Track?.BusBar, Track?.Color);
    }
    return [];
  }, [Track]);

  const step1ContextData = { Color, setColor, Mount, setMount, BusBar, setBusBar };
  const step2ContextData = { A, B, C, D, activeSide, setActiveSide };
  const step3ContextData = { scaleX, setScaleX, setScaleY, x, setX, y, setY, scaleY, activeSide, setActiveSide };

  function sideUpdate() {
    project.getType();
  }

  useEffect(() => {
    setTimeout(() => {
      if (DATA) {
        project.fromString(DATA, {
          setColor,
          setMount,
          setBusBar
        });
      }
    }, 100);
  }, [DATA, project, Spots]);
  useEffect(() => {
    window.$(document).on('sideUpdate', sideUpdate);
    return () => {
      window.$(document).off('sideUpdate', sideUpdate);
    };
  });
  const setTrack = (value?: Track) => {
    _setTrack(value);
    project.setTrack(value);
    $(document).trigger('TrackUpdate', value);
  };

  const reset = useCallback(() => {
    window.location.href = '/';
  }, []);

  useEffect(() => {
    window.$(document).on('reset', reset);
    return () => {
      window.$(document).on('reset', reset);
    };
  }, [reset]);
  return (
    <>
      <globalContext.Provider value={{ isAuth, Track, setTrack, project, Spots }}>
        <Routes>
          <Route path='/' element={<Layout/>}>  {/*  Menu */}
            <Route
              index element={
              <step1Context.Provider value={step1ContextData}>
                <Step1 />
              </step1Context.Provider>
            }
            />
            <Route
              path='step2' element={
              <step2Context.Provider value={step2ContextData}>
                <Step2 />
              </step2Context.Provider>
            }
            />
            <Route
              path='step3' element={
              <step3Context.Provider value={step3ContextData}>
                <Step3 />
              </step3Context.Provider>
            }
            />
            <Route path='step4' element={<Step4 />} />
            <Route
              path='step5' element={<>
              <step1Context.Provider value={step1ContextData}>
                <Step1 />
              </step1Context.Provider>
              <step2Context.Provider value={step2ContextData}>
                <Step2 />
              </step2Context.Provider>
              <step3Context.Provider value={step3ContextData}>
                <Step3 />
              </step3Context.Provider>

              <Step4 />

            </>

            }
            />
            <Route path='*' element={<NotFound />} />
          </Route>
        </Routes>
        <div className={isDesktop ? 'modal' : 'modal show'} id='modalNotAvailable'>
          <div className='modal__dialog'>
            <div className='modal__content'>
              <div className='modal__header'>
                <h4 className='modal__title'>Конфигуратор доступен на десктопный устройствах</h4>
              </div>
              <div className='modal__body'>
                <p className='modal__subtitle'>
                  <p>Ваше устройство не поддерживается.</p>
                  <p>Минимальное разрешение экрана для корректной работы конфигуратора {innerWidthLimit} пикселей</p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </globalContext.Provider>
    </>
  );
}