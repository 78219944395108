import { Sides } from '../../classes/Side';

export const SummaryItem = (props: {

  s: {name: Sides, data: {length: number, image: string, title: string, article: string, count: number}[], fasteners: {article: string, title: string, image: string, count: number}[]}
}) => {

  const { s } = props;
  return (
    <>
      <tr>
        <td className='conf__summary-column conf__summary-column_caption' colSpan={99}>Сторона {s.name}</td>
      </tr>
      {s.data.map((data) => {
        return <tr key={data.length}>
          <td className='conf__summary-column conf__summary-column_image' style={{ textAlign: 'left', width: 50 }}><img
            style={{ height: 50 }} alt={data.article} title={data.article} src={data.image || 'https://via.placeholder.com/50'}
          /></td>
          <td className='conf__summary-column conf__summary-column_name'>{data.title}</td>
          <td className='conf__summary-column conf__summary-column_length'>{data.length}</td>
          <td className='conf__summary-column conf__summary-column_value'>{data.count}</td>
        </tr>;
      })}
      {s.fasteners.map((data) => {
        return <tr key={data.article}>
          <td className='conf__summary-column conf__summary-column_image' style={{ textAlign: 'left', width: 50 }}><img
            style={{ maxHeight: 50, maxWidth: 50 }} alt={data.article} title={data.article} src={data.image || 'https://via.placeholder.com/50'}
          /></td>
          <td className='conf__summary-column conf__summary-column_name' colSpan={2}>{data.title}</td>
          <td className='conf__summary-column conf__summary-column_value'>{data.count}</td>
        </tr>;
      })}
    </>
  );
};