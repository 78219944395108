import React, { useCallback, useContext, useEffect, useReducer, useRef } from 'react';
import { globalContext } from '../../context/global';
import { _Shape, Painter } from '../../classes/Painter';
import { step2Context } from '../../context/step2';
import { step3Context } from '../../context/step3';

export const Model = () => {
  const { setScaleX, setScaleY, setX, setY, x, y, scaleX, scaleY, activeSide,setActiveSide } = useContext(step3Context);

  const { project } = useContext(globalContext);
  const ref = useRef<HTMLDivElement>(null);


  const setDefaults = useCallback((stage: _Shape) => {
    setScaleX(stage.getAttr<number>('scaleX'));
    setScaleY(stage.getAttr<number>('scaleY'));
    setX(stage.getAttr<number>('x'));
    setY(stage.getAttr<number>('y'));
  }, [setScaleX, setScaleY, setX, setY]);
  const [force, forceUpdate] = useReducer(x => x + 1, 1);

  function sideUpdate() {
    forceUpdate();
  }

  useEffect(() => {
    window.$(document).on('sideUpdate', sideUpdate);
    return () => {
      window.$(document).off('sideUpdate', sideUpdate);
    };
  });
  useEffect(() => {
    if (ref && ref.current && force) {
      const p = new Painter(project, {
        container: ref.current,
        width: ref.current.clientWidth,
        height: ref.current.clientHeight,
        draggable: true
      });
      p.on('click', (shape, track, side) => {
        setActiveSide(side.name);
      }).on('wheel', function(stage) {
        setDefaults(stage);
      }).on('dragend', function(stage) {
        setDefaults(stage);
      });
      if (scaleX) {
        p.stage.setAttr('scaleX', scaleX);
      }
      if (scaleY) {
        p.stage.setAttr('scaleY', scaleY);
      }
      if (x) {
        p.stage.setAttr('x', x);
      }
      if (y) {
        p.stage.setAttr('y', y);
      }
      p.render(activeSide).then(r => {
      });
    }
  }, [project, ref, scaleX, scaleY, setActiveSide,activeSide, force, setDefaults, x, y]);

  return (
    <>
      <section className='conf__presentation'>
        <div className='conf__viewport' ref={ref}>

        </div>
      </section>
    </>
  );
};