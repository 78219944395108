import { createContext } from 'react';
import { BusBarType, ColorType, MountType } from '../../data/tracks';

interface step1 {
  Color?: ColorType;
  setColor: (value: ColorType) => void;
  Mount?: MountType;
  setMount: (value: MountType) => void;
  BusBar?: BusBarType;
  setBusBar: (value: BusBarType) => void;
}

export const step1Context = createContext<step1>({
  setBusBar(value: BusBarType): void {
  }, setColor(value: ColorType): void {
  }, setMount(value: MountType): void {
  }
});