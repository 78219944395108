import { getImageByArticle } from './data';

export enum MountType {
  invoice = 'накладной',
  embedded = 'встраиваемый в гипсокартон',
  suspended = 'подвесной',
  stretchCeiling = 'встраиваемый под натяжной потолок'
}

export enum BusBarType {
  mini = 'мини',
  fullSize = 'стандартный',
}

export enum ColorType {
  black = 'черный',
  white = 'белый',
  gray = 'серый'
}

export type Articles = { 2000: TrackItemType }

export type Accessories = {
  AngularConnection: AccessoriesItemType[]
  AngularConnectionPower: AccessoriesItemType[]
  DirectConnection: AccessoriesItemType[]
  necessary?: AccessoriesItemType[]
  stubs?: AccessoriesItemType[]
  profiles?: { 2000: AccessoriesItemType }
}

export interface TrackType {
  name: string;//unique identifier
  image: string;
  Color: ColorType;
  BusBar: BusBarType;
  Mount: MountType;
  Articles: Articles;
  Accessories: Accessories;
}

export type AccessoriesItemType = {
  title?: string
  article: string
  price?: number
  image?: string
}

export type TrackItemType = {
  title?: string
  article: string
  price?: number
  image?: string
}

export class Track implements TrackType {
  public name: string;
  public image: string;
  public BusBar: BusBarType;
  public Color: ColorType;
  public Mount: MountType;
  public Accessories: Accessories;
  public Articles: Articles;

  constructor(data: TrackType) {
    this.BusBar = data.BusBar;
    this.Accessories = data.Accessories;
    this.Articles = data.Articles;
    this.Color = data.Color;
    this.Mount = data.Mount;
    this.name = data.name;
    this.image = data.image;
    this.getImage();
  }

  getImage(article: keyof Articles = 2000) {
    const art = this.Articles[article];
    this.image = getImageByArticle(art.article);
  }

  toArray() {
    return {
      BusBar: this.BusBar,
      Color: this.Color,
      Mount: this.Mount
    };
  }

}

export function getTrackByConfig(conf: { Mount: MountType, BusBar: BusBarType, Color: ColorType }): Track | undefined {
  for (let i = 0; i < Tracks.length; i++) {
    if (Tracks[i].Mount === conf.Mount && Tracks[i].Color === conf.Color && Tracks[i].BusBar === conf.BusBar) {
      return Tracks[i];
    }
  }
  return undefined;
}

export function getTrackListByConfig(conf: { Mount?: MountType, BusBar?: BusBarType, Color?: ColorType }): Track[] {
  const result: Track[] = [];
  for (let i = 0; i < Tracks.length; i++) {
    if (
      (!conf.Mount || Tracks[i].Mount === conf.Mount) &&
      (!conf.Color || Tracks[i].Color === conf.Color) &&
      (!conf.BusBar || Tracks[i].BusBar === conf.BusBar)
    ) {
      result.push(Tracks[i]);
    }
  }
  return result;
}

export const Tracks: Track[] = [
  new Track({
    image: '',
    name: 'мини черный накладной',
    Color: ColorType.black,
    Articles: {
      2000: {
        article: '80003-2M-BK'
      }
    },
    Accessories: {
      AngularConnection: [{ article: '82005-BK' }],
      AngularConnectionPower: [{ article: '84002-BK' }],
      DirectConnection: [
        {
          article: '83001-BK'
        },
        {
          article: '84003-BK'
        }],
      necessary: []
    },
    Mount: MountType.invoice,
    BusBar: BusBarType.mini
  }),

  new Track({
    image: '',
    name: 'стандартный черный накладной',
    BusBar: BusBarType.fullSize,
    Color: ColorType.black,
    Mount: MountType.invoice,
    Articles: {
      2000: {
        article: '80002-2M-BK'
      }
    },
    Accessories: {
      AngularConnection: [{ article: '82003-BK' }],
      AngularConnectionPower: [{ article: '84002-BK' }],
      DirectConnection: [{
        article: '83001-BK'
      }, {
        article: '84003-BK'
      }],
      necessary: []
    }

  }),
  new Track({
    image: '',
    name: 'стандартный белый накладной',
    Color: ColorType.white,
    Articles: {
      2000: {
        article: '80002-2M-WH'
      }
    },
    Accessories: {
      AngularConnection: [{ article: '82003-WH' }],
      AngularConnectionPower: [{ article: '84002-WH' }],
      DirectConnection: [{
        article: '83001-BK'
      }, {
        article: '84003-WH'
      }],
      necessary: []
    },
    Mount: MountType.invoice,
    BusBar: BusBarType.fullSize
  }),

  new Track({
    image: '',
    name: 'стандартный черный встраиваемый под гипсокартон',
    Color: ColorType.black,
    Articles: {
      2000: {
        article: '80001-2M-BK'
      }
    },
    Accessories: {
      AngularConnection: [{ article: '82001-BK' }],
      AngularConnectionPower: [{ article: '82002-BK' }],
      DirectConnection: [{
        article: '83001-BK'
      }, {
        article: '84003-BK'
      }],
      necessary: []
    },
    Mount: MountType.embedded,
    BusBar: BusBarType.fullSize
  }),
  new Track({
    image: '',
    name: 'стандартный белый встраиваемый под гипсокартон',
    Color: ColorType.white,
    Articles: {
      2000: {
        article: '80001-2M-WH'
      }
    },
    Accessories: {
      AngularConnection: [{ article: '82001-WH' }],
      AngularConnectionPower: [{ article: '84002-WH' }],
      DirectConnection: [{
        article: '83001-BK'
      }, {
        article: '84003-WH'
      }],
      necessary: []
    },
    Mount: MountType.embedded,
    BusBar: BusBarType.fullSize
  }),

  new Track({
    image: '',
    name: 'мини черный подвесной',
    Color: ColorType.black,
    Articles: {
      2000: {
        article: '80003-2M-BK'
      }
    },
    Accessories: {
      AngularConnection: [{ article: '82005-BK' }],
      AngularConnectionPower: [{ article: '84002-BK' }],
      DirectConnection: [{
        article: '83001-BK'
      }, {
        article: '84003-BK'
      }],
      necessary: [{ article: '83002-BK' }]
    },
    Mount: MountType.suspended,
    BusBar: BusBarType.mini
  }),

  new Track({
    image: '',
    name: 'стандартный черный подвесной',
    Color: ColorType.black,
    Articles: {
      2000: {
        article: '80002-2M-BK'
      }
    },
    Accessories: {
      AngularConnection: [{ article: '82003-BK' }],
      AngularConnectionPower: [{ article: '84002-BK' }],
      DirectConnection: [{
        article: '83001-BK'
      }, {
        article: '84003-BK'
      }],
      necessary: [{ article: '83002-BK' }]

    },
    Mount: MountType.suspended,
    BusBar: BusBarType.fullSize
  }),
  new Track({
    image: '',
    name: 'стандартный белый подвесной',
    Color: ColorType.white,
    Articles: {
      2000: {
        article: '80002-2M-WH'
      }
    },
    Accessories: {
      AngularConnection: [{ article: '82003-WH' }],
      AngularConnectionPower: [{ article: '84002-WH' }],
      DirectConnection: [{
        article: '83001-BK'
      }, {
        article: '84003-WH'
      }],
      necessary: [{ article: '83002-WH' }]
    },
    Mount: MountType.suspended,
    BusBar: BusBarType.fullSize
  }),

  new Track({
    image: '',
    name: 'стандартный черный под натяжной потолок',
    Color: ColorType.black,
    Articles: {
      2000: {
        article: '80004-2M-BK'
      }
    },
    Accessories: {
      AngularConnection: [{ article: '83005-4-NI' }],
      AngularConnectionPower: [{ article: '84002-BK' }],
      DirectConnection: [{
        article: '83004-4-NI'
      }, {
        article: '84003-BK'
      }],
      stubs: [{article: '81004-BK'}]
    },
    Mount: MountType.stretchCeiling,
    BusBar: BusBarType.fullSize
  }),
  new Track({
    image: '',
    name: 'стандартный белый под натяжной потолок',
    Color: ColorType.white,
    Articles: {
      2000: {
        article: '80004-2M-WH'
      }
    },
    Accessories: {
      AngularConnection: [{ article: '83005-4-NI' }],
      AngularConnectionPower: [{ article: '84002-WH' }],
      DirectConnection: [{
        article: '83004-4-NI'
      }, {
        article: '84003-WH'
      }],
      stubs: [{article: '81004-WH'}]
    },
    Mount: MountType.stretchCeiling,
    BusBar: BusBarType.fullSize
  })
];
Tracks.sort((A, B) => {
  if (A.Color > B.Color) {
    return -1;
  } else if (A.Color < B.Color) {
    return 1;
  } else {
    if (A.BusBar > B.BusBar) {
      return 1;
    } else if (A.BusBar < B.BusBar) {
      return -1;
    } else {
      if (A.Mount > B.Mount) {
        return 1;
      } else if (A.Mount < B.Mount) {
        return -1;
      } else {
        return 0;
      }
    }
  }
});
export default Tracks;
