import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { globalContext } from '../context/global';
import { BusBarType } from '../../data/tracks';
import usePopper from '../hooks/usePopper';

export const Footer = () => {
  const { Track, project } = useContext(globalContext);
  const [nextPage, setNextPage] = useState('/');
  const [AllowNextStep, setAllowNextStep] = useState(false);
  const location = useLocation();
  const [popperBody, setPopperBody] = useState('Не выбран тип трека');
  const Popper = usePopper({ element: '#footerNextBtn', body: popperBody });

  const router = () => {
    setAllowNextStep(false);
    switch (location.pathname) {
      case '/':
        setNextPage('/step2');
        if (Track) {
          setPopperBody('');
          setAllowNextStep(true);
        } else {
          setPopperBody('Не выбран тип трека');
        }
        break;
      case '/step2':
        setNextPage('/step3');
        if (project.getSideCount() > 0) {
          setAllowNextStep(true);
          setPopperBody('');
        } else {
          setAllowNextStep(false);
          setPopperBody('Не добавлен ни 1 трек');
        }
        break;
      case '/step3':
        setNextPage('/step4');
        if (project.getSidesPower() > 0 && (project.Track?.BusBar === BusBarType.fullSize || project.getPower())) {
          setAllowNextStep(true);
          setPopperBody('');
        } else {
          setAllowNextStep(false);
          let msg = [];
          if (!project.getPower()) {
            msg.push('не выбран блок питания');
          }
          if (!project.getSidesPower()) {
            msg.push('не добавлен ни 1 светильник');
          }
          setPopperBody(msg.join(', '));
        }
        break;
      case '/step4':
        setNextPage('/step5');
        break;
    }

  }

  useEffect(router, [Track, location, project]);

  function sideUpdate() {
    router()
  }

  function scrollTop(){
    $(window).scrollTop($('.header').position().top);
  }

  useEffect(() => {
    window.$(document).on('sideUpdate', sideUpdate);
    window.$(document).on('sideItemsUpdate', sideUpdate);
    return () => {
      window.$(document).off('sideUpdate', sideUpdate);
      window.$(document).off('sideItemsUpdate', sideUpdate);
    };
  });
if(nextPage === '/step5'){
  return (
    <footer className='footer'>
      {/*  */}
      <div className='conf__wrapper'>
        {/* Контейнер для незаполненной конфигурации (.footer__description), оставлять пустым */}
        <div className='footer__description'></div>
        {/* Зла блокированной кнопке добавлять класс inactive */}
      </div>
    </footer>
  );
}
  return (
    <>
      <footer className='footer'>
        {/*  */}
        <div className='conf__wrapper'>
          {/* Контейнер для незаполненной конфигурации (.footer__description), оставлять пустым */}
          <div className='footer__description'></div>
          {/* Зла блокированной кнопке добавлять класс inactive */}
          <span
            id={'footerNextBtn'} onMouseEnter={() => {
            Popper.showPopper();
          }} onMouseLeave={() => {
            Popper.hidePopper();
          }}
            onClick={scrollTop}
          ><NavLink
            className={AllowNextStep ? 'footer__bnt' : 'footer__bnt disabled'} to={nextPage}
          >Следующий шаг</NavLink></span>
        </div>
      </footer>
      {Popper.html}
    </>
  );
};