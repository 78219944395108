import * as React from 'react';
import { useCallback, useEffect, useRef } from 'react';
import { createPopper } from '@popperjs/core';


export default function usePopper(props: { element: |string, body: string | React.DOMElement<any, any> }) {
  const { element, body } = props;
  const tooltip = useRef(null);
  let popperInstance: any = null;
  const showPopper = useCallback(() => {
    if (tooltip.current && popperInstance && body) {
      window.$(tooltip.current).attr('data-show', '');
      popperInstance.setOptions((options: { modifiers: any; }) => ({
        ...options,
        modifiers: [
          ...options.modifiers,
          { name: 'eventListeners', enabled: true }
        ]
      }));
      popperInstance.update();
    }
  }, [body, popperInstance]);


  // eslint-disable-next-line react-hooks/exhaustive-deps
  function setPopperInstance(value: any) {
    popperInstance = value;
  }

  const hidePopper = useCallback(() => {
    if (tooltip.current && popperInstance && body) {
      window.$(tooltip.current).removeAttr('data-show');
      popperInstance.setOptions((options: { modifiers: any; }) => ({
        ...options,
        modifiers: [
          ...options.modifiers,
          { name: 'eventListeners', enabled: false }
        ]
      }));
      popperInstance.update();
    }
  }, [body, popperInstance]);

  useEffect(() => {
    if (!tooltip.current || !element) {
      return;
    }
    const _element: HTMLElement = window.$(element)[0];
    const popperInstance = createPopper(_element, tooltip.current, {
      placement: 'bottom-start',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 12]
          }
        }
      ]
    });
    setPopperInstance(popperInstance);
  }, [tooltip, element, setPopperInstance]);

  const html = <>
    {body ?
      <div ref={tooltip} className='tooltip' id='tooltipMessage' role='tooltip'>
        {body}
        <div className='tooltip__arrow' data-popper-arrow=''></div>
      </div>
      : ''}
  </>;
  return {html, showPopper, hidePopper};

}