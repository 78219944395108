import { Project, Variants } from './Project';
import { AccessoriesItemType, MountType, Track } from '../../data/tracks';
import { Side } from './Side';
import { Spot } from '../../data/spots';
import { getImageByArticle, getTitleByArticle } from '../../data/data';

export interface SummaryAccessoriesItemType extends AccessoriesItemType {
  count: number,
  title: string
}

export class Summary {
  constructor(public project: Project) {

  }

  getType(): Variants {
    return this.project.getType();
  }

  getTrack(): Track {
    if (!this.project.Track) throw new Error('Track not found');
    return this.project.Track;
  }

  getPreparedSides() {
    const sides = [];
    for (const key in this.project.Sides) {
      const s = this.project.Sides[key];
      if (s.isIsset()) {
        const { data, fasteners } = this.prepareSide(s);
        sides.push({
          name: s.name,
          data,
          fasteners
        });
      }
    }
    return sides;
  }

  prepareSide(side: Side) {
    const result: { [key: number]: { length: number, image: string, title: string, article: string, count: number } } = {};
    for (const key in side.Tracks) {
      const article = side.Tracks[key].getArticle();
      const title = side.Tracks[key].getTitle();
      const image = side.Tracks[key].getImage();
      const length = side.Tracks[key].length;
      if (result[length] === undefined) {
        result[length] = {
          length,
          'article': '',
          'title': '',
          'image': '',
          'count': 0
        };
      }
      result[length]['title'] = title;
      result[length]['article'] = article;
      result[length]['image'] = image;
      result[length]['count']++;

    }
    const fasteners: {
      article: string,
      title: string,
      image: string,
      count: number
    }[] = [];
    const tracks = Object.values(side.Tracks);
    if (tracks && tracks.length > 0) {
      let count = tracks.length-1;
      if (count < 0) {
        count = 0;
      }
      if (count) {
        for (const k in tracks[0].track.Accessories.DirectConnection) {
          const DirectConnection = tracks[0].track.Accessories.DirectConnection[k];
          fasteners.push({
            article: DirectConnection.article,
            title: getTitleByArticle(DirectConnection.article) || DirectConnection.article,
            image: DirectConnection.image || getImageByArticle(DirectConnection.article),
            count: count
          });
        }
      }
      if (count <= 0) {
        count = 1;
      }
      if (tracks[0].track.Accessories.necessary) {
        for (const k in tracks[0].track.Accessories.necessary) {
          const necessary = tracks[0].track.Accessories.necessary[k];
          fasteners.push({
            article: necessary.article,
            title: getTitleByArticle(necessary.article) || necessary.article,
            image: necessary.image || getImageByArticle(necessary.article),
            count: count
          });
        }
      }
    }
    return { data: Object.values(result), fasteners };
  }

  accessories() {
    let result: { [p: string]: SummaryAccessoriesItemType } = {};
    try {
      const type = this.getType();
      switch (type) {
        case Variants.Angle1:
        case Variants.Angle2:
        case Variants.Angle3:
        case Variants.Angle4:
          result = this.accessories_Angle();
          break;
        case Variants.Triangle1:
        case Variants.Triangle2:
        case Variants.Triangle3:
        case Variants.Triangle4:
          result = this.accessories_Triangle();
          break;
        case Variants.Rect:
          result = this.accessories_Rect();
          break;
      }
      if (this.project.Track) {
        if (type !== Variants.Rect && this.project.Track.Mount === MountType.stretchCeiling && this.project.getLength() >0) {
          if (this.project.Track.Accessories.stubs) {
            for (const stubsKey in this.project.Track.Accessories.stubs) {
              const stub = this.project.Track.Accessories.stubs[stubsKey];
              if(type === Variants.parallel || type === Variants.parallelVertical){
                result[stub.article] = { article: stub.article, count: 4, title: getTitleByArticle(stub.article) };
              }else{
                result[stub.article] = { article: stub.article, count: 2, title: getTitleByArticle(stub.article) };
              }
            }
          }
        }
      }
    } catch (e) {
      return [];
    }
    return Object.values(result);

  }

  profiles(): SummaryAccessoriesItemType[] {
    if (this.project.Track?.Accessories?.profiles && Object.keys(this.project.Track?.Accessories?.profiles).length > 0) {
      const len = this.project.getLength();
      if (!len) {
        return [];
      }
      const count = Math.ceil(len / 2000);
      const article = this.project.Track?.Accessories?.profiles[2000].article;
      const title = getTitleByArticle(article);
      return [
        {
          article,
          title,
          count
        }
      ];
    }
    return [];
  }

  accessories_Angle() {
    const track = this.getTrack();
    const response: { [key: string]: SummaryAccessoriesItemType } = {};
    for (const angularConnectionKey in track.Accessories.AngularConnection) {
      const a = track.Accessories.AngularConnection[angularConnectionKey];
      response[a.article] = { ...a, count: 1, title: getTitleByArticle(a.article) };
      response[a.article].image = getImageByArticle(a.article);
    }
    for (const angularConnectionKey in track.Accessories.AngularConnectionPower) {
      const a = track.Accessories.AngularConnectionPower[angularConnectionKey];
      response[a.article] = { ...a, count: 1, title: getTitleByArticle(a.article) };
      response[a.article].image = getImageByArticle(a.article);
    }
    return response;
  }

  accessories_Triangle() {
    const track = this.getTrack();
    const response: { [key: string]: SummaryAccessoriesItemType } = {};
    for (const angularConnectionKey in track.Accessories.AngularConnection) {
      const a = track.Accessories.AngularConnection[angularConnectionKey];
      response[a.article] = { ...a, count: 2, title: getTitleByArticle(a.article) };
      response[a.article].image = getImageByArticle(a.article);
    }
    for (const angularConnectionKey in track.Accessories.AngularConnectionPower) {
      const a = track.Accessories.AngularConnectionPower[angularConnectionKey];
      response[a.article] = { ...a, count: 2, title: getTitleByArticle(a.article) };
      response[a.article].image = getImageByArticle(a.article);
    }
    return response;
  }

  accessories_Rect() {
    const track = this.getTrack();
    const response: { [key: string]: SummaryAccessoriesItemType } = {};
    let multiple = 3;
    if (this.project.A.length === this.project.C.length && this.project.D.length === this.project.B.length) {
      multiple = 4;
    }
    for (const angularConnectionKey in track.Accessories.AngularConnection) {
      const a = track.Accessories.AngularConnection[angularConnectionKey];
      response[a.article] = { ...a, count: multiple, title: getTitleByArticle(a.article) };
      response[a.article].image = getImageByArticle(a.article);
    }
    if (multiple === 4) {
      multiple = 3;
    }
    for (const angularConnectionKey in track.Accessories.AngularConnectionPower) {
      const a = track.Accessories.AngularConnectionPower[angularConnectionKey];
      response[a.article] = { ...a, count: multiple, title: getTitleByArticle(a.article) };
      response[a.article].image = getImageByArticle(a.article);
    }
    return response;
  }

  spots() {
    const spots: { [p: string]: { spot: Spot, count: number } } = {};
    for (const key in this.project.Sides) {
      const s = this.project.Sides[key];
      if (s.isIsset()) {
        const _spots = s.getSpots();
        for (const spotsKey in _spots) {
          if (spots[spotsKey] === undefined) {
            spots[spotsKey] = _spots[spotsKey];
          } else {
            spots[spotsKey].count += _spots[spotsKey].count;
          }
        }
      }
    }
    return spots;
  }

  summary() {
    const sides = this.getPreparedSides();
    const accessories = this.accessories();
    const profiles = this.profiles();
    const spots = this.spots();
    const power = this.project.getPower();
    return { sides, accessories, spots, power, profiles };
  }
}