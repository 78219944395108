import { createContext } from 'react';
import { Track } from '../../data/tracks';
import { Project } from '../classes/Project';
import { Spot } from '../../data/spots';

interface global {
  isAuth:boolean
  Track?: Track;
  setTrack: (value: Track | undefined) => void;
  project: Project;
  Spots: Spot[];
}

// @ts-ignore
export const globalContext = createContext<global>({
  isAuth:false,
  setTrack(value: Track | undefined): void {
  }
});