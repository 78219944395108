import { SpotsSide } from './SpotsSide';
import React, { useCallback, useContext, useState } from 'react';
import { globalContext } from '../../context/global';
import { step3Context } from '../../context/step3';
import { Sides } from '../../classes/Side';
import { SpotItem } from './SpotItem';
import { Spot } from '../../../data/spots';

export const Spots = () => {
  const { project, Spots } = useContext(globalContext);
  const { activeSide, setActiveSide } = useContext(step3Context);
  const [search, setSearch] = useState('');
  const addSpot = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>, spot: Spot) => {
    if (activeSide) {
      let response = false;
      switch (activeSide) {
        case Sides.A:
          response = project.A.addSpot(spot);
          break;
        case Sides.B:
          response = project.B.addSpot(spot);
          break;
        case Sides.C:
          response = project.C.addSpot(spot);
          break;
        case Sides.D:
          response = project.D.addSpot(spot);
          break;
      }
      if (response) {
        addSpotAnimation(e, `#side-${activeSide}`);
      }
    }
  }, [activeSide, project]);
  const filter = useCallback((spot: Spot) => {
    if (search) {
      return spot.name.toUpperCase().search(search.toUpperCase()) >= 0;
    }
    return true;
  }, [search]);

  function addSpotAnimation(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, outElem: JQuery.Selector) {
    const $ = window.$;
    const out = $(outElem).offset();
    if (!out) {
      return;
    }
    const anime = $(`<i id='test' style='z-index: 9989; width: 10px;height: 10px;background-color: gray;border-radius: 50%; position: absolute;display:block'></i>`).appendTo('body');
    anime.offset({
      top: e.pageY,
      left: e.pageX
    });
    anime.animate({
      left: out.left,
      top: out.top,
      opacity: 0
    });
    setTimeout(function(anime) {
      anime.remove();
    }, 1000, anime);
  }

  return (
    <>
      <section className='conf__content'>
        <ul className='conf__sides-list'>
          <SpotsSide side={Sides.A} isSelected={project.A.isIsset()} active={activeSide} onSelect={(side) => setActiveSide(side)} />
          <SpotsSide side={Sides.B} isSelected={project.B.isIsset()} active={activeSide} onSelect={(side) => setActiveSide(side)} />
          <SpotsSide side={Sides.C} isSelected={project.C.isIsset()} active={activeSide} onSelect={(side) => setActiveSide(side)} />
          <SpotsSide side={Sides.D} isSelected={project.D.isIsset()} active={activeSide} onSelect={(side) => setActiveSide(side)} />
        </ul>
        <label className='conf__search'>
          <input
            type='text' placeholder='найти' onChange={(e) => {
            setSearch(e.target.value);
          }}
          />
          {/* <button>поиск</button> */}
        </label>
        <ul className='conf__module-list'>
          {Spots.filter(filter).map((spot: Spot) => {
            return <SpotItem count={0} key={spot.article} spot={spot} onClick={(e, spot) => addSpot(e, spot)} />;
            })}
          </ul>
        </section>
      </>
    );
  }
;