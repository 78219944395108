import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { DevSupport } from '@react-buddy/ide-toolbox';
import { ComponentPreviews, useInitial } from './dev';
import { BrowserRouter } from 'react-router-dom';
import $ from 'jquery';
import { Side } from './components/classes/Side';
import { cookieEvent } from './CookeiEvent';

declare global {
  interface DocumentEventMap{
    "cookieChanged": typeof cookieEvent;
  }
  interface Window {
    PROJECT_NAME?:string
    $: typeof $;
    jQuery: typeof $;
    sides: { [key: string]: Side };
    DATA?: string;
  }
}

document.addEventListener('DOMContentLoaded', function(e) {
  if (e.target) e.target.dispatchEvent(cookieEvent);
});
window.$ = $;
window.sides = {};
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <DevSupport
        ComponentPreviews={ComponentPreviews}
        useInitialHook={useInitial}
      >
        <App />
      </DevSupport>
    </React.StrictMode>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
