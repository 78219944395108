import { useContext } from 'react';
import { step2Context } from '../../context/step2';
import { RectSide } from './RectSide';
import { RectButtons } from './RectButtons';
import { RectTrackLine } from './RectTrackLine';
import { globalContext } from '../../context/global';
import { Sides } from '../../classes/Side';

export const Rect = () => {
  const { project } = useContext(globalContext);
  const { A, B, C, D, activeSide, setActiveSide } = useContext(step2Context);
  return (
    <>
      <section className='conf__equipment'>
        <div className='conf__sides'>
          <RectSide side={Sides.A} isSelected={A.isIsset()} active={activeSide} onSelect={(side) => setActiveSide(side)}></RectSide>
          <RectSide side={Sides.B} isSelected={B.isIsset()} active={activeSide} onSelect={(side) => setActiveSide(side)}></RectSide>
          <RectSide side={Sides.C} isSelected={C.isIsset()} active={activeSide} onSelect={(side) => setActiveSide(side)}></RectSide>
          <RectSide side={Sides.D} isSelected={D.isIsset()} active={activeSide} onSelect={(side) => setActiveSide(side)}></RectSide>
        </div>
        <div className='conf__elements'>
          <div className='conf__elements-controllers'>
            <RectButtons />
          </div>
          <div className='conf__elements-wrapper'>
            <RectTrackLine side={A}></RectTrackLine>
            <RectTrackLine side={B}></RectTrackLine>
            <RectTrackLine side={C}></RectTrackLine>
            <RectTrackLine side={D}></RectTrackLine>
          </div>
          <div className='conf__elements-result'>Общая наружная длина {project.getLength()} мм</div>
        </div>
      </section>
    </>
  );
};