import { Spot } from '../../../data/spots';
import React from 'react';

export const SpotItem = (props: { spot: Spot, onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, spot: Spot) => void, cls?: string, count: number }) => {
  const { spot, onClick, cls, count } = props;
  return (
    <>
      <li className={cls ?? 'conf__module-item'}>
        <button className='conf__data-btn' onClick={(e) => onClick(e, spot)}>
          <span className='conf__data-pic' style={{ backgroundImage: 'url(\'' + spot.image + '\')' }}></span>
          <span className='conf__data-label'>{spot.name}</span>
          <span className='conf__data-size'>{spot.connector_length} MM</span>
          <span className='conf__data-price'>цена {spot.price} ₽</span>
          <span className='conf__data-size'>мощность {spot.power || 0} W</span>
          {count ? <span className='conf__data-badge'>+{count}</span> : <></>}
        </button>
      </li>
    </>
  );
};