import * as React from 'react';
import { Track } from '../../../data/tracks';

export const SelectTrackItem = (props: { track: Track, active?: boolean, onClick?: (track: Track) => void }) => {
  const { track, active, onClick } = props;
  let cls = 'conf__selects-img inactive';
  if (active) {
    cls = 'conf__selects-img';
  }
  return (
    <div
      className={cls} onClick={() => {
      if (onClick) onClick(track);
    }}
    >
      <img src={track.image} alt={track.name} />
    </div>
  );
};