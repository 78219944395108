import { createContext } from 'react';
import { Sides } from '../classes/Side';

interface step3 {
  setScaleX: (value: number) => void
  setScaleY: (value: number) => void
  setX: (value: number) => void
  setY: (value: number) => void
  scaleY: number,
  scaleX: number,
  x: number,
  y: number,
  activeSide:Sides
  setActiveSide:(value: Sides) => void
}

export const step3Context = createContext<step3>({
  activeSide: Sides.A, setActiveSide(value: Sides): void {
  },
  scaleX: 0, scaleY: 0, setScaleX(value: number): void {
  }, setScaleY(value: number): void {
  }, setX(value: number): void {
  }, setY(value: number): void {
  }, x: 0, y: 0
});