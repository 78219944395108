import { Outlet } from 'react-router-dom';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Header } from './Header';
import { Footer } from './Footer';


export const Layout = () => {
  const innerWidthLimit = 1200;
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= innerWidthLimit);
  const onResize = useCallback(() => {
    setIsDesktop(window.innerWidth >= innerWidthLimit);
  }, []);
  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);
  return (
    <>
      <Header isDesktop={isDesktop} />
      <Outlet />
      <Footer />
    </>
  );
};