import { Sides } from '../../classes/Side';


export const SpotsSide = (props: { side: Sides, active: Sides, isSelected: boolean, onSelect: (side: Sides) => void }) => {
  const { side, active, isSelected, onSelect } = props;
  return (
    <>
      <li className='conf__sides-item'>
        <button onClick={() => onSelect(side)} className={`${active === side ? 'active' : `${isSelected ? 'selected' : ''}`}`}>сторона<strong>{side}</strong></button>
      </li>
    </>
  );
};