import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Radio } from '../ui/step1/Radio';
import { BusBarType, ColorType, getTrackListByConfig, MountType, Track } from '../../data/tracks';
import { SelectTrack } from '../ui/step1/SelectTrack';
import { step1Context } from '../context/step1';
import { globalContext } from '../context/global';

export const Step1 = () => {
  const { Color, setColor, Mount, setMount, BusBar, setBusBar } = useContext(step1Context);
  const { setTrack } = useContext(globalContext);
  const [Tracks, setTracks] = useState<Track[]>([]);

  useEffect(() => {
    const list = getTrackListByConfig({
      Color: Color,
      Mount: Mount,
      BusBar: BusBar
    });
    setTracks(list);
    if (list.length === 1) {
      setTrack(list[0]);
    } else {
      setTrack(undefined);
    }
  }, [Color, Mount, BusBar, setTracks, setTrack]);
  const [BusBars, setBusBars] = useState<Set<BusBarType>>(new Set());
  const [Mounts, setMounts] = useState<Set<MountType>>(new Set());
  const [Colors, setColors] = useState<Set<ColorType>>(new Set());
  useEffect(() => {
    const _BusBars = new Set<BusBarType>();
    const _Mounts = new Set<MountType>();
    const _Colors = new Set<ColorType>();
    Tracks.forEach((track) => {
      _BusBars.add(track.BusBar);
      _Mounts.add(track.Mount);
      _Colors.add(track.Color);
    });
    setBusBars(_BusBars);
    setMounts(_Mounts);
    setColors(_Colors);
  }, [Tracks]);

  return (
    <>
      <main className='main'>
        <div className='conf__wrapper'>
          <section className='conf__selects'>
            <h2 className='conf__selects-title'>Выберите тип профиля</h2>
            <Radio currentValue={Mount} name={'Mount'} canBeSelected={Mounts.has(MountType.invoice)} value={MountType.invoice} onChange={setMount} />
            <Radio currentValue={Mount} name={'Mount'} canBeSelected={Mounts.has(MountType.embedded)} value={MountType.embedded} onChange={setMount} />
            <Radio currentValue={Mount} name={'Mount'} canBeSelected={Mounts.has(MountType.suspended)} value={MountType.suspended} onChange={setMount} />
            <Radio currentValue={Mount} name={'Mount'} canBeSelected={Mounts.has(MountType.stretchCeiling)} value={MountType.stretchCeiling} onChange={setMount} />
          </section>
          <section className='conf__selects'>
            <h2 className='conf__selects-title'>Выберите цвет</h2>
            <Radio currentValue={Color} name={'Color'} canBeSelected={Colors.has(ColorType.black)} value={ColorType.black} onChange={setColor} />
            <Radio currentValue={Color} name={'Color'} canBeSelected={Colors.has(ColorType.white)} value={ColorType.white} onChange={setColor} />
          </section>
          <section className='conf__selects'>
            <h2 className='conf__selects-title'>Выберите вид трека</h2>
            <Radio currentValue={BusBar} name={'BusBar'} canBeSelected={BusBars.has(BusBarType.mini)} value={BusBarType.mini} onChange={setBusBar} />
            <Radio currentValue={BusBar} name={'BusBar'} canBeSelected={BusBars.has(BusBarType.fullSize)} value={BusBarType.fullSize} onChange={setBusBar} />
          </section>
          <section className='conf__selects'>
            <SelectTrack Tracks={Tracks} />
          </section>
        </div>
      </main>
    </>
  );
};