import { Track } from '../../../data/tracks';
import * as React from 'react';
import { useCallback, useContext } from 'react';
import { SelectTrackItem } from './SelectTrackItem';
import { step1Context } from '../../context/step1';

export const SelectTrack = (props: { Tracks: Track[] }) => {
  const { Tracks } = props;
  const { setColor, setMount, setBusBar } = useContext(step1Context);

  const select = useCallback((track: Track) => {
    setColor(track.Color);
    setMount(track.Mount);
    setBusBar(track.BusBar);
  }, [setBusBar, setColor, setMount]);
  return (
    <>
      <h2 className='conf__selects-title'>Выберите трек</h2>
      <div className='conf__selects-list'>
        {Tracks.length ?
          <>
            {Tracks.map((Track) =>
              <SelectTrackItem key={Track.name} track={Track} active={Tracks.length === 1} onClick={select} />
            )}
          </>
          : <>
            <h2>Таких треков не существует </h2>
          </>
        }
      </div>
    </>
  );
};