import { RightPanelSide } from './RightPanelSide';
import { Sides } from '../../classes/Side';
import { useContext } from 'react';
import { globalContext } from '../../context/global';
import { step3Context } from '../../context/step3';
import { RightPanelPower } from './RightPanelPower';

export const RightPanel = () => {
  const { project } = useContext(globalContext);
  const { activeSide, setActiveSide } = useContext(step3Context);
  return (
    <>
      <section className='conf__data custom-scroll-vertical' style={{ overflow: 'auto' }}>

        <RightPanelPower />
        <div className='conf__data-wrap custom-scroll-vertical'>
          {project.A.isIsset() ? <RightPanelSide side={Sides.A} isSelected={project.A.isIsset()} active={activeSide} onSelect={(side) => setActiveSide(side)} /> : <></>}
          {project.B.isIsset() ? <RightPanelSide side={Sides.B} isSelected={project.B.isIsset()} active={activeSide} onSelect={(side) => setActiveSide(side)} /> : <></>}
          {project.C.isIsset() ? <RightPanelSide side={Sides.C} isSelected={project.C.isIsset()} active={activeSide} onSelect={(side) => setActiveSide(side)} /> : <></>}
          {project.D.isIsset() ? <RightPanelSide side={Sides.D} isSelected={project.D.isIsset()} active={activeSide} onSelect={(side) => setActiveSide(side)} /> : <></>}
        </div>
      </section>
    </>
  );
};