import { ColorType, MountType, Track } from './tracks';
import { getImageByArticle, getPriceByArticle, getTitleByArticle } from './data';

export enum PowerWattType {
  lower = 100,
  middle = 150,
  high = 200,
  reactor = 240
}

export enum PowerMountType {
  embedded = 'встраиваемый',
  remote = 'выносной'
}

export interface PowerType {
  article: string;
  name: string;
  image: string;
  Color: ColorType;
  Power: PowerWattType;
  Mount: PowerMountType;
  dimming: boolean;
}

export interface PowerConnectorType {
  article: string;
  name: string;
  image: string;
  Color: ColorType;
  Mount: MountType;
  connector_length: number;
  cable_length: number;
}

export class PowerConnector implements PowerConnectorType {
  Color: ColorType;
  Mount: MountType;
  article: string;
  cable_length: number;
  connector_length: number;
  image: string;
  name: string;

  constructor(data: PowerConnectorType) {
    this.Color = data.Color;
    this.Mount = data.Mount;
    this.article = data.article;
    this.cable_length = data.cable_length;
    this.connector_length = data.connector_length;
    this.image = data.image;
    this.name = data.name;
  }
}

export class Power implements PowerType {
  Color: ColorType;
  Mount: PowerMountType;
  image: string;
  article: string;
  name: string;
  price: number;
  Power: PowerWattType;
  dimming: boolean;

  constructor(data: PowerType) {
    this.article = data.article;
    this.name = data.name;
    this.Color = data.Color;
    this.Mount = data.Mount;
    this.image = data.image;
    this.Power = data.Power;
    this.dimming = data.dimming;
    this.price = getPriceByArticle(this.article);
  }

  getAccessories(track: Track): PowerConnector[] {
    console.log('test')
    if (this.Mount === PowerMountType.embedded) {
      return [];
    }
    const result: PowerConnector[] = [];
    PowerAccessories.forEach((connector) => {
      if (track.Color === connector.Color) {
        if (track.Mount === MountType.suspended) {
          if (connector.Mount === MountType.suspended) {
            result.push(connector);
          }
        }
        if (connector.Mount !== MountType.suspended) {
          result.push(connector);
        }
      }
    });
    return result;
  }

  toArray() {
    return {
      article: this.article
    };
  }
}

export const PowerAccessories: PowerConnector[] = [
  new PowerConnector({
    image: getImageByArticle('83003-BK'),
    name: getTitleByArticle('83003-BK'),
    article: '83003-BK',
    Color: ColorType.black,
    connector_length: 8.7,
    cable_length: 50,
    Mount: MountType.suspended
  }), new PowerConnector({
    image: getImageByArticle('83003-BK'),
    name: getTitleByArticle('83003-BK'),
    article: '83003-WH',
    Color: ColorType.white,
    connector_length: 8.7,
    cable_length: 50,
    Mount: MountType.suspended
  }), new PowerConnector({
    image: getImageByArticle('84001-BK'),
    name: getTitleByArticle('84001-BK'),
    article: '84001-BK',
    Color: ColorType.black,
    connector_length: 8.7,
    cable_length: 50,
    Mount: MountType.invoice
  }), new PowerConnector({
    image: getImageByArticle('84001-WH'),
    name: getTitleByArticle('84001-WH'),
    article: '84001-WH',
    Color: ColorType.white,
    connector_length: 8.7,
    cable_length: 50,
    Mount: MountType.invoice
  })
];

export function getPowerVariants(track: Track, power: number): Power[] {
  const list = powers.filter((item) => {
    // блоки без цены
    if (!item.price) return false;
    // расчет подходящей мощности
    if (power > item.Power * 0.7) return false;
    // исключение встраиваемых блоков не подходящего цвета
    // if (item.Mount === PowerMountType.embedded && item.Color !== track.Color) return false;
    return true;
  });
  return list.sort((A, B) => {
    if (!A.article || !B.article) {
      return 0;
    }
    if (A.Mount > B.Mount) {
      return 1;
    } else if (A.Mount < B.Mount) {
      return -1;
    } else {
      if (A.Power > B.Power) {
        return 1;
      } else if (A.Power < B.Power) {
        return -1;
      } else {
        return 0;
      }
    }
  })
}

export function getPowerByArticle(article: string): Power | undefined {
  let power;
  powers.forEach((item) => {
    if (item.article === article) {
      power = item;
    }
  });
  return power;
}
export const powers: Power[] = [
  new Power({
    article: '85100-48',
    Power: PowerWattType.lower,
    image: getImageByArticle('85100-48'),
    name: getTitleByArticle('85100-48'),
    Color: ColorType.white,
    Mount: PowerMountType.remote,
    dimming: false
  }),
  new Power({
    article: '85240-48',
    Power: PowerWattType.reactor,
    image: getImageByArticle('85240-48'),
    name: getTitleByArticle('85240-48'),
    Color: ColorType.white,
    Mount: PowerMountType.remote,
    dimming: false
  })
];
export default powers;