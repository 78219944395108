import { BusBarType, ColorType } from './tracks';
import { getData, getImageByArticle, getPriceByArticle } from './data';

const data = getData();

export interface SpotType {
  name: string;
  article: string;
  image: string;
  connector_length: number;
  price: number;
  power: number;
}

export class Spot implements SpotType {
  article: string;
  connector_length: number;
  image: string;
  name: string;
  price: number;
  power: number;

  constructor(data: SpotType) {
    this.article = data.article;
    this.connector_length = data.connector_length;
    this.image = data.image;
    this.name = data.name;
    this.price = data.price;
    this.power = data.power;
  }

  toArray() {
    return {
      article: this.article,
      connector_length: this.connector_length,
      image: this.image,
      name: this.name,
      price: this.price
    };
  }

}

export let Spots: Spot[] = [];

export function getSpotsFromData(BusBar: BusBarType, Color: ColorType) {
  Spots = [];
  const SpotsData = [];
  for (const dataKey in data) {
    try {
      const item = data[dataKey];
      switch (BusBar) {
        case BusBarType.mini:
        case BusBarType.fullSize:
          if (item.category !== 'Светильники') {
            continue;
          }
          if (item.armature_color.length === 0) {
            continue;
          }
          if (Color.toUpperCase() !== item.armature_color[0].toUpperCase()) {
            continue;
          }
          break;

      }
      if (!getPriceByArticle(dataKey)) {
        continue;
      }
      item.article = dataKey;
      SpotsData.push(item);

    } catch (e) {
      console.error(e);
    }
  }
  SpotsData.sort((A, B) => {
    if (!A.article || !B.article) {
      return 0;
    }
    if (A.collection > B.collection) {
      return 1;
    } else if (A.collection < B.collection) {
      return -1;
    } else {
      return 0;
    }
  });
  SpotsData.forEach((item) => {
    if (item.article && item.track_length) {
      Spots.push(new Spot({
        article: item.article,
        name: item.title,
        image: getImageByArticle(item.article),
        connector_length: item.track_length,
        price: getPriceByArticle(item.article),
        power: item.power
      }));
    }
  });
  return Spots;
}

export function getSpotByArticle(article: string): Spot | undefined {
  for (const spotsKey in Spots) {
    if (Spots[spotsKey].article === article) {
      return Spots[spotsKey];
    }
  }
  return undefined;
}

export default Spots;